import { configureStore } from '@reduxjs/toolkit';

import { sessionReducer } from './pages/Login/reducer';
import { edocReducer } from './pages/DocumentosElectronicos/reducer';
import { dashboardReducer } from './pages/Dashboard/reducer';
import { reporteReducer } from './pages/Reportes/reducer';

export default configureStore({
    reducer: {
        session: sessionReducer.reducer,
        edoc: edocReducer.reducer,
        dashboard: dashboardReducer.reducer,
        reportes: reporteReducer.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['reportes.fromDate', 'reportes.toDate']
            }
        })
});
