import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React from 'react';
import { FloatLabel } from 'primereact/floatlabel';
import { TIPO_IDENTIFICACION_VEHICULO } from '../constants/transporte_mercaderias';
import AutoFocusDropdown from '../../../../components/AutoFocusDropdown';

const GVehTras = (props) => {
    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que identifican el vehículo de traslado de mercaderías</b>
                </Divider>
                <div className="field col-12 md:col-2">
                    <FloatLabel>
                        <InputText
                            id="tipoVeh"
                            value={props.formik.values.d_ti_veh_tras}
                            onChange={(e) => props.formik.setFieldValue('d_ti_veh_tras', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_ti_veh_tras', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ti_veh_tras') })}
                        />
                        <label htmlFor="tipoVeh">Tipo de vehículo</label>
                        {props.getFormErrorMessage('d_ti_veh_tras')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-2">
                    <FloatLabel>
                        <InputText
                            id="marcaVeh"
                            value={props.formik.values.d_mar_veh}
                            onChange={(e) => props.formik.setFieldValue('d_mar_veh', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_mar_veh', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_mar_veh') })}
                        />
                        <label htmlFor="marcaVeh">Marca del vehículo</label>
                        {props.getFormErrorMessage('d_mar_veh')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <AutoFocusDropdown
                            id="tipoIdentVeh"
                            value={props.formik.values.tipo_iden_veh}
                            checkmark={true}
                            onChange={(e) => props.formik.setFieldValue('tipo_iden_veh', e.value)}
                            onBlur={() => props.formik.setFieldTouched('tipo_iden_veh', true)}
                            options={TIPO_IDENTIFICACION_VEHICULO}
                            optionLabel="d_des_tip_iden_veh"
                            placeholder="Seleccione el tipo de identificación del vehículo"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_iden_veh') })}
                        />
                        <label htmlFor="tipoIdentVeh">Tipo de identificación del vehículo</label>
                        {props.getFormErrorMessage('tipo_iden_veh')}
                    </FloatLabel>
                </div>
                {props.formik.values.tipo_iden_veh?.d_tip_iden_veh === 1 && (
                    <div className="field col-12 md:col-2">
                        <FloatLabel>
                            <InputText
                                id="nroIdentVeh"
                                value={props.formik.values.d_nro_id_veh}
                                onChange={(e) => props.formik.setFieldValue('d_nro_id_veh', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_nro_id_veh', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nro_id_veh') })}
                            />
                            <label htmlFor="nroIdentVeh">Identificación del vehículo</label>
                            {props.getFormErrorMessage('d_nro_id_veh')}
                        </FloatLabel>
                    </div>
                )}
                {props.formik.values.tipo_iden_veh?.d_tip_iden_veh === 2 && (
                    <div className="field col-12 md:col-2">
                        <FloatLabel>
                            <InputText
                                id="nroMatrVeh"
                                value={props.formik.values.d_nro_mat_veh}
                                onChange={(e) => props.formik.setFieldValue('d_nro_mat_veh', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_nro_mat_veh', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nro_mat_veh') })}
                            />
                            <label htmlFor="nroMatrVeh">Matrícula del vehículo</label>
                            {props.getFormErrorMessage('d_nro_mat_veh')}
                        </FloatLabel>
                    </div>
                )}
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText
                            id="datosAdVeh"
                            value={props.formik.values.d_adic_veh}
                            onChange={(e) => props.formik.setFieldValue('d_adic_veh', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_adic_veh', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_adic_veh') })}
                        />
                        <label htmlFor="datosAdVeh">Datos adicionales del vehículo</label>
                        {props.getFormErrorMessage('d_adic_veh')}
                    </FloatLabel>
                </div>
                {props.formik.values.modalidad_transporte?.i_mod_trans === 3 && (
                    <div className="field col-12 md:col-2">
                        <FloatLabel>
                            <InputText
                                id="nroVuelo"
                                value={props.formik.values.d_nro_vuelo}
                                onChange={(e) => props.formik.setFieldValue('d_nro_vuelo', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_nro_vuelo', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nro_vuelo') })}
                            />
                            <label htmlFor="nroVuelo">Nro. de vuelo</label>
                            {props.getFormErrorMessage('d_nro_vuelo')}
                        </FloatLabel>
                    </div>
                )}
            </div>
        </>
    );
};

export default GVehTras;
