import * as Yup from 'yup';
import { isEmpty } from 'lodash';

const validationSchema = Yup.object().shape({
    tipo_de: Yup.object({
        d_des_ti_de: Yup.string(),
        i_ti_de: Yup.number()
    }),
    timbrado: Yup.object({
        nro_timbrado: Yup.string().label('timbrado'),
        establecimiento: Yup.number(),
        punto_expedicion: Yup.number(),
        name: Yup.string()
    }),
    d_fe_emi_de: Yup.date().required('Campo requerido').label('Fecha de emisión'),
    tipo_trans: Yup.object({
        i_tip_tra: Yup.number(),
        d_des_trip_tra: Yup.string().label('Tipo de transacción')
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 1 && keys[0]?.i_ti_de !== 4) {
                    return true;
                }
                return (keys[0].i_ti_de === 1 || keys[0].i_ti_de === 4) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    tipo_imp_afectado: Yup.object({
        i_timp: Yup.number(),
        d_des_timp: Yup.string().label('Tipo de impuesto afectado')
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de === 7) {
                    return true;
                }
                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    moneda_operacion: Yup.object({
        c_mone_ope: Yup.string(),
        d_des_mone_ope: Yup.string().label('Moneda de la operación')
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de === 7) {
                    return true;
                }
                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    condicion_tipo_cambio: Yup.object({
        d_cond_ti_cam: Yup.number(),
        d_des_cond_ti_cam: Yup.string().label('Condición del tipo de cambio')
    }).when(['moneda_operacion', 'tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[1]?.i_ti_de === 7) {
                    return true;
                }
                if (keys[0]?.c_mone_ope === 'PYG') {
                    return true;
                }
                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_ti_cam: Yup.number()
        .label('Tipo de cambio de la operación')
        .when(['condicion_tipo_cambio', 'moneda_operacion'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[1]?.c_mone_ope === 'PYG') return true;

                    return !(keys[0]?.d_cond_ti_cam === 1 && value === 0);
                },
                message: 'Campo requerido.'
            });
        }),
    g_emis: Yup.object({
        ruc: Yup.object({
            code: Yup.number(),
            name: Yup.string()
        })
    }).required(),
    nat_rec: Yup.object({
        i_nat_rec: Yup.number().label('Naturaleza del receptor'),
        d_des_nat_rec: Yup.string().label('Naturaleza del receptor')
    })
        .label('Naturaleza del receptor')
        .when([], (keys, schema) => {
            return schema.test({
                test: (value) => !isEmpty(value),
                message: 'Campo requerido.'
            });
        }),
    tipo_op_rec: Yup.object({
        i_ti_ope: Yup.number().label('Tipo de operación'),
        d_des_ti_ope: Yup.string().label('Tipo de operación')
    })
        .label('Tipo de operación')
        .when([], (keys, schema) => {
            return schema.test({
                test: (value) => !isEmpty(value),
                message: 'Campo requerido.'
            });
        }),
    pais_rec: Yup.object({
        codigo: Yup.string().label('País'),
        nombre: Yup.string().label('País')
    })
        .label('País')
        .when([], (keys, schema) => {
            return schema.test({
                test: (value) => !isEmpty(value),
                message: 'Campo requerido.'
            });
        }),
    tipo_cont_rec: Yup.object({
        i_ti_con_rec: Yup.number(),
        d_des_ti_con_rec: Yup.string()
    })
        .label('Tipo de contribuyente receptor')
        .when(['nat_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_nat_rec !== 1) return true;

                    return keys[0]?.i_nat_rec === 1 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_ruc_rec: Yup.string()
        .label('RUC')
        .min(3)
        .max(8)
        .when(['nat_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_nat_rec === 2) return true;

                    return keys[0]?.i_nat_rec === 1 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_doc_rec: Yup.object({
        i_tip_id_rec: Yup.number(),
        d_d_tip_id_rec: Yup.string()
    })
        .label('Tipo de documento de identidad')
        .when(['nat_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_nat_rec === 1) return true;

                    return keys[0]?.i_nat_rec === 2 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    desc_tipo_doc: Yup.string()
        .label('Descripción del tipo de documento de identidad')
        .min(9, 'Descripción del tipo de documento de identidad debe tener al menos 9 caracteres.')
        .max(41, 'Descripción del tipo de documento de identidad debe tener como máximo 41 caracteres.')
        .optional()
        .when(['tipo_doc_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_id_rec !== 9) return true;

                    return keys[0]?.i_tip_id_rec === 9 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_id_rec: Yup.string()
        .label('Nro. de documento')
        .when(['nat_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    schema.min(1).max(20);
                    if (keys[0]?.i_nat_rec === 1) return true;

                    return keys[0]?.i_nat_rec === 2 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_nom_rec: Yup.string().min(4).max(255).trim().required('Campo requerido.').label('Razón social'),
    d_dir_rec: Yup.string()
        .label('Dirección')
        .min(1)
        .max(255)
        .when(['tipo_de', 'tipo_op_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 && keys[1]?.i_ti_ope !== 4) return true;

                    return (keys[0]?.i_ti_de === 7 || keys[1]?.i_ti_ope === 4) && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_cas_rec: Yup.string()
        .label('Nro. de Casa')
        .when(['d_dir_rec'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (isEmpty(keys[0])) return true;
                    return !isEmpty(keys[0]) && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    departamento_rec: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['d_dir_rec', 'tipo_op_rec'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (isEmpty(keys[0]) || keys[1]?.i_ti_ope === 4) return true;

                return !isEmpty(keys[0]) && keys[1]?.i_ti_ope !== 4 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    distrito_rec: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['departamento_rec'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (isEmpty(keys[0])) return true;

                return !isEmpty(keys[0]) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    localidad_rec: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['distrito_rec'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (isEmpty(keys[0])) return true;

                return !isEmpty(keys[0]) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_tel_rec: Yup.string().label('Nro. Teléfono').min(6).max(15).optional(),
    d_cel_rec: Yup.string().label('Nro. Celular').min(10).max(20).optional(),
    d_email_rec: Yup.string().email().label('E-mail'),
    modalidad_venta: Yup.object({
        i_ind_pres: Yup.number(),
        d_des_ind_pres: Yup.string().label('Modalidad de venta')
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 1) return true;
                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_mod_cont: Yup.string()
        .label('Modalidad')
        .when('tipo_op_rec.i_ti_ope', {
            is: 3,
            then: (schema) => schema.length(2).required()
        }),
    d_ent_cont: Yup.number()
        .label('Entidad')
        .when('tipo_op_rec.i_ti_ope', {
            is: 3,
            then: (schema) => schema.max(99999).required()
        }),
    d_ano_cont: Yup.number()
        .label('Año')
        .when('tipo_op_rec.i_ti_ope', {
            is: 3,
            then: (schema) => schema.max(99).required()
        }),
    d_sec_cont: Yup.number()
        .label('Secuencia')
        .when('tipo_op_rec.i_ti_ope', {
            is: 3,
            then: (schema) => schema.max(9999999).required()
        }),
    d_fe_cod_cont: Yup.date()
        .label('Fecha emisión')
        .when('tipo_op_rec.i_ti_ope', {
            is: 3,
            then: (schema) => schema.required()
        }),
    cond_ope: Yup.object({
        i_cond_ope: Yup.number(),
        d_d_cond_ope: Yup.string()
    })
        .label('Condición de la operación')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 1 && keys[0]?.i_ti_de !== 4) return true;
                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_pago: Yup.object({
        i_ti_pago: Yup.number(),
        d_des_ti_pag: Yup.string()
    }).when(['cond_ope', 'd_mon_ent', 'tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[2]?.i_ti_de !== 1 && keys[2]?.i_ti_de !== 4) return true;

                if (keys[0].i_cond_ope === 2 || keys[1] <= 0) return true;

                return (keys[0].i_cond_ope === 1 || keys[1] > 0) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_mon_ti_pag: Yup.number(),
    moneda_tipo_pago: Yup.object({
        code: Yup.string(),
        name: Yup.string()
    }).when(['cond_ope', 'd_mon_ent', 'tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[2]?.i_ti_de !== 1 && keys[2]?.i_ti_de !== 4) return true;
                if (keys[0].i_cond_ope === 2 || keys[1] <= 0) return true;
                return (keys[0].i_cond_ope === 1 || keys[1] > 0) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_ti_cam_ti_pag: Yup.number()
        .nullable()
        .label('Tipo de cambio por tipo de pago')
        .when(['moneda_tipo_pago'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (isEmpty(keys[0]) || keys[0]?.code === 'PYG') return true;

                    return keys[0]?.code !== 'PYG' && value > 0;
                },
                message: 'Campo requerido.'
            });
        }),
    iden_tarj: Yup.object({
        i_den_tarj: Yup.number(),
        d_des_den_tarj: Yup.string()
    }).when(['tipo_pago'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_pago !== 3 && keys[0]?.i_ti_pago !== 4) return true;

                return (keys[0].i_ti_pago === 3 || keys[0].i_ti_pago === 4) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    forma_proc_pago: Yup.object({
        i_for_pro_pa: Yup.number(),
        d_des_for_pra: Yup.string()
    }).when(['tipo_pago'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_pago !== 3 && keys[0]?.i_ti_pago !== 4) return true;

                return (keys[0].i_ti_pago === 3 || keys[0].i_ti_pago === 4) && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_num_cheq: Yup.string().when('tipo_pago.i_ti_pago', {
        is: 2,
        then: (schema) => schema.length(8, 'Completar con 0 (cero) a la izquierda hasta alcanzar 8 (ocho) cifras').label('Número de cheque').required()
    }),
    d_b_co_emi: Yup.string().when('tipo_pago.i_ti_pago', {
        is: 2,
        then: (schema) => schema.min(4).max(20).label('Banco emisor').required()
    }),
    cond_cred: Yup.object({
        i_cond_cred: Yup.number(),
        dd_cond_cred: Yup.string()
    }).when(['cond_ope'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_cond_ope !== 2) return true;

                return keys[0].i_cond_ope === 2 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_plazo_cre: Yup.string().when('cond_cred.i_cond_cred', {
        is: 1,
        then: (schema) => schema.min(4).max(20).label('Plazo del crédito').required()
    }),
    d_cuotas: Yup.number(),
    d_mon_ent: Yup.number().label('Monto de la entrega inicial').nullable().optional(),
    mot_emision_ncnd: Yup.object({
        i_mot_emi: Yup.number(),
        d_des_mot_emi: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 5 && keys[0]?.i_ti_de !== 4) return true;

                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    mot_emision_nr: Yup.object({
        i_mot_emi_nr: Yup.number(),
        d_des_mot_emi_nr: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    resp_emision_nr: Yup.object({
        i_resp_emi_nr: Yup.number(),
        d_des_resp_emi_nr: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_km_r: Yup.number()
        .label('Kilómetros estimado de recorrido')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && value;
                },
                message: 'Campo requerido.'
            });
        }),
    d_fec_em: Yup.string()
        .label('Fecha futura de emisión de la factura')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_transporte: Yup.object({
        i_tip_trans: Yup.number(),
        d_des_tip_trans: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    modalidad_transporte: Yup.object({
        i_mod_trans: Yup.number(),
        d_des_mod_trans: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    responsable_flete: Yup.object({
        i_resp_flete: Yup.number(),
        d_des_resp_flete: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    condicion_negociacion: Yup.object({
        c_cond_neg: Yup.string(),
        d_des_cond_neg: Yup.string()
    })
        .nullable()
        .optional(),
    d_nu_desp_imp: Yup.string()
        .nullable()
        .optional()
        .label('Nro de Despacho de importación')
        .min(16)
        .max(16)
        .when(['tipo_de', 'mot_emision_nr'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.i_mot_emi_nr !== 5) return true;

                    return keys[0]?.i_ti_de === 7 && keys[1]?.i_mot_emi_nr === 5 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_ini_tras: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_fin_tras: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    pais_destino: Yup.object({
        c_pais_dest: Yup.string(),
        d_des_pais_dest: Yup.string()
    })
        .optional()
        .nullable(),
    d_dir_loc_sal: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_num_cas_sal: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    dpto_local_salida: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    dist_local_salida: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    loc_local_salida: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_dir_loc_ent: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_num_cas_ent: Yup.string().when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    dpto_local_entrega: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    dist_local_entrega: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    loc_local_entrega: Yup.object({
        codigo: Yup.number(),
        nombre: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_ti_veh_tras: Yup.string()
        .min(4)
        .max(10)
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_mar_veh: Yup.string()
        .min(1)
        .max(10)
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_iden_veh: Yup.object({
        d_tip_iden_veh: Yup.number(),
        d_des_tip_iden_veh: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_nro_id_veh: Yup.string()
        .min(1)
        .max(20)
        .when(['tipo_de', 'tipo_iden_veh'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.d_tip_iden_veh !== 1) return true;

                    return keys[0].i_ti_de === 7 && keys[1]?.d_tip_iden_veh === 1 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_adic_veh: Yup.string().nullable().optional().label('Datos adicionales del vehículo').min(1).max(20),
    d_nro_mat_veh: Yup.string()
        .optional()
        .nullable()
        .label('Número de matrícula')
        .max(7)
        .when(['tipo_de', 'tipo_iden_veh'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.d_tip_iden_veh !== 2) return true;

                    return keys[0].i_ti_de === 7 && keys[1]?.d_tip_iden_veh === 2 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_nro_vuelo: Yup.string()
        .min(6)
        .max(6)
        .when(['tipo_de', 'modalidad_transporte'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.i_mod_trans !== 3) return true;

                    return keys[0].i_ti_de === 7 && keys[1]?.i_mod_trans === 3 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    naturaleza_transportista: Yup.object({
        i_nat_trans: Yup.number(),
        d_des_nat_trans: Yup.string()
    }).when(['tipo_de'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7) return true;

                return keys[0].i_ti_de === 7 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_nom_trans: Yup.string()
        .min(4)
        .max(60)
        .label('Nombre del transportista')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_ruc_trans: Yup.string()
        .min(3)
        .max(8)
        .optional()
        .nullable()
        .when(['tipo_de', 'naturaleza_transportista'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.i_nat_trans !== 1) return true;

                    return keys[0].i_ti_de === 7 && keys[1]?.i_nat_trans === 1 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_documento_transportista: Yup.object({
        i_tip_id_trans: Yup.number(),
        dd_tip_id_trans: Yup.string()
    }).when(['tipo_de', 'naturaleza_transportista'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_ti_de !== 7 || keys[1]?.i_nat_trans !== 2) return true;

                return keys[0].i_ti_de === 7 && keys[1]?.i_nat_trans === 2 && !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    d_num_id_trans: Yup.string()
        .optional()
        .nullable()
        .when(['tipo_de', 'naturaleza_transportista'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7 || keys[1]?.i_nat_trans !== 2) return true;

                    return keys[0].i_ti_de === 7 && keys[1]?.i_nat_trans === 2 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_id_chof: Yup.string()
        .min(1)
        .max(20)
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_nom_chof: Yup.string()
        .min(4)
        .max(60)
        .label('Nombre del chofer')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_dom_fisc: Yup.string()
        .min(1)
        .max(150)
        .label('Dirección fiscal del transportista')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_dir_chof: Yup.string()
        .min(1)
        .max(255)
        .label('Dirección del chofer')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 7) return true;

                    return keys[0].i_ti_de === 7 && !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    g_cam_item: Yup.array()
        .of(
            Yup.object({
                d_cod_int: Yup.string(),
                d_dncp_g: Yup.string(),
                d_dncp_e: Yup.string(),
                d_des_pro_ser: Yup.string(),
                unidad_medida: Yup.object({
                    c_uni_med: Yup.number(),
                    d_des_uni_med: Yup.string()
                }),
                d_cant_pro_ser: Yup.number(),
                g_valor_item: Yup.object({
                    d_p_uni_pro_ser: Yup.number(),
                    d_tot_bru_ope_item: Yup.number(),
                    g_valor_resta_item: Yup.object({
                        d_desc_item: Yup.number().optional(),
                        d_porc_des_it: Yup.number().optional(),
                        d_desc_glo_item: Yup.number().optional(),
                        d_ant_pre_uni_lt: Yup.number().optional(),
                        d_ant_glo_pre_unit_lt: Yup.number().optional(),
                        d_tot_ope_item: Yup.number(),
                        d_tot_ope_gs: Yup.number().optional()
                    })
                }),
                g_cam_iva: Yup.object({
                    afectacion_tributaria: Yup.object({
                        i_afec_iva: Yup.number(),
                        d_des_afec_iva: Yup.string()
                    }),
                    d_prop_iva: Yup.number(),
                    d_tasa_iva: Yup.number(),
                    d_bas_grav_iva: Yup.number(),
                    d_liq_iva_item: Yup.number(),
                    d_bas_exe: Yup.number()
                })
            })
        )
        .required(),
    g_obl_afe: Yup.array()
        .of(
            Yup.object({
                c_obl_afe: Yup.number(),
                d_des_obl_afe: Yup.string()
            })
        )
        .optional()
        .nullable(),
    tipo_doc_asoc: Yup.object({
        i_tip_doc_aso: Yup.number(),
        d_des_tip_doc_aso: Yup.string()
    })
        .label('Tipo de documento asociado')
        .when(['tipo_de'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_ti_de !== 4 && keys[0]?.i_ti_de !== 5 && keys[0]?.i_ti_de !== 6) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_cd_cde_ref: Yup.string()
        .label('CDC del documento asociado')
        .min(44)
        .max(44)
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 1) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    dn_tim_di: Yup.string()
        .label('Nro. Timbrado')
        .min(8)
        .max(8)
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 2) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_est_doc_aso: Yup.string()
        .label('Establecimiento')
        .min(3)
        .max(3)
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 2) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    dp_exp_doc_aso: Yup.string()
        .label('Punto de expedición')
        .min(3)
        .max(3)
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 2) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_doc_aso: Yup.string()
        .label('Nro. Documento')
        .min(7)
        .max(7)
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 2) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    tipo_doc_asoc_impreso: Yup.object({
        i_tipo_doc_aso: Yup.number(),
        dd_tipo_doc_aso: Yup.string()
    })
        .label('Tipo de documento asociado impreso')
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 2) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_fec_emi_di: Yup.string().when(['tipo_doc_asoc'], (keys, schema) => {
        return schema.test({
            test: (value) => {
                if (keys[0]?.i_tip_doc_aso !== 2) return true;

                return !isEmpty(value);
            },
            message: 'Campo requerido.'
        });
    }),
    tipo_constancia: Yup.object({
        i_tip_cons: Yup.number(),
        d_des_tip_cons: Yup.string()
    })
        .label('Tipo de constancia asociado impreso')
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 3) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_cons: Yup.number()
        .label('Nro. Constancia')
        .optional()
        .nullable()
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 3) return true;

                    return value > 0;
                },
                message: 'Campo requerido.'
            });
        }),
    d_num_control: Yup.string()
        .label('Nro. de control de constancia')
        .min(8)
        .max(8)
        .optional()
        .nullable()
        .when(['tipo_doc_asoc'], (keys, schema) => {
            return schema.test({
                test: (value) => {
                    if (keys[0]?.i_tip_doc_aso !== 3) return true;

                    return !isEmpty(value);
                },
                message: 'Campo requerido.'
            });
        })
});

export default validationSchema;
