//Afectación tributaria.
export const AFECTACION_TRIBUTARIA_GRAVADO_IVA = 1;
export const AFECTACION_TRIBUTARIA_EXONERADO = 2;
export const AFECTACION_TRIBUTARIA_EXENTO = 3;
export const AFECTACION_TRIBUTARIA_GRABADO_PARCIAL = 4;

export const TIPO_DOCUMENTOS_ELECTRONICOS = [
    {
        code: '1',
        name: 'Factura electrónica'
    },
    {
        code: '2',
        name: 'Factura electrónica de exportación'
    },
    {
        code: '3',
        name: 'Factura electrónica de importación'
    },
    {
        code: '4',
        name: 'Autofactura electrónica'
    },
    {
        code: '5',
        name: 'Nota de crédito electrónica'
    },
    {
        code: '6',
        name: 'Nota de débito electrónica'
    },
    {
        code: '7',
        name: 'Nota de remisión electrónica'
    },
    {
        code: '8',
        name: 'Comprobante de retención electrónico'
    }
];
