import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { Skeleton } from 'primereact/skeleton';
import AutoFocusDropdown from './AutoFocusDropdown';

const SeleccionarLocalidad = (props) => {
    const httpClient = useHttpClient();
    const [localidades, setLocalidades] = useState([]);
    const [currentDist, setCurrentDist] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.distrito && props.distrito !== currentDist) {
            setCurrentDist(props.distrito);
            setIsLoading(true);
            httpClient
                .get(`api/localidades/${props.distrito}`)
                .then((response) => {
                    setLocalidades(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.distrito, currentDist]);

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        return (
            <AutoFocusDropdown
                id={props.id}
                filter
                emptyMessage="No se econtraron localidades, por favor selecione otro distrito"
                value={props.value}
                onChange={(e) => props.onChange(e)}
                onBlur={() => props.onBlur()}
                options={localidades}
                checkmark={true}
                optionLabel="nombre"
                placeholder="Seleccionar Localidad"
                className={props.className}
                virtualScrollerOptions={{ itemSize: 38 }}
            />
        );
    };

    return getSelect();
};

export default SeleccionarLocalidad;
