import { useHttpClient } from '../../hooks/useHttpClient';
import { useDispatch, useSelector } from 'react-redux';
import { addLocale } from 'primereact/api';
import React, { useEffect, useState } from 'react';
import { FloatLabel } from 'primereact/floatlabel';
import { Calendar } from 'primereact/calendar';
import { updateFromDate, updateIssuer, updateTipoDE, updateToDate } from './reducer';
import SeleccionarEmisor from '../../components/SeleccionarEmisor';
import { TIPO_DOCUMENTOS_ELECTRONICOS } from '../../utilities/constants';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import AutoFocusDropdown from '../../components/AutoFocusDropdown';

const Ventas = () => {
    const httpClient = useHttpClient();
    const fromDate = useSelector((state) => state.reportes.fromDate);
    const toDate = useSelector((state) => state.reportes.toDate);
    const tipoDE = useSelector((state) => state.reportes.tipoDE);
    const issuer = useSelector((state) => state.reportes.issuer);
    const dispatch = useDispatch();
    const [disablefilter, setDisableFilter] = useState(true);
    const [loading, setLoading] = useState(false);

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    useEffect(() => {
        if (issuer?.code > 0) {
            setDisableFilter(false);
            return;
        }
        setDisableFilter(true);
    }, [issuer]);

    useEffect(() => {
        if (toDate.getTime() > fromDate.getTime()) {
            setDisableFilter(false);
            return;
        }
        setDisableFilter(true);
    }, [fromDate, toDate]);

    const formatDate = (date) => {
        return format(date, "yyyy-MM-dd'T'HH:mm:ss");
    };

    const downloadFile = () => {
        setLoading(true);

        //change the time frame of both dates
        fromDate.setHours(0, 0, 0);
        toDate.setHours(23, 59, 59);
        const body = {
            emisor: issuer.code,
            desde: formatDate(fromDate),
            hasta: formatDate(toDate),
            tipo_de: tipoDE?.code > 0 ? Number(tipoDE?.code) : 0
        };
        httpClient
            .post('admin/de/reporte/ventas', body, { headers: { 'Content-Type': 'blob' }, responseType: 'arraybuffer' })
            .then((response) => {
                const fileName = `ventas_${issuer.code}.xlsx`;
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        encoding: 'UTF-8'
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Filtros</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Calendar
                                    id="desde"
                                    value={fromDate}
                                    onChange={(e) => {
                                        dispatch(
                                            updateFromDate({
                                                fromDate: e.value
                                            })
                                        );
                                    }}
                                    dateFormat="dd-mm-yy"
                                    showButtonBar={true}
                                    locale="es"
                                    selectionMode={'single'}
                                />
                                <label htmlFor="desde">
                                    Desde <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Calendar
                                    id="hasta"
                                    value={toDate}
                                    onChange={(e) => {
                                        dispatch(
                                            updateToDate({
                                                toDate: e.value
                                            })
                                        );
                                    }}
                                    dateFormat="dd-mm-yy"
                                    showButtonBar={true}
                                    selectionMode={'single'}
                                    locale="es"
                                />
                                <label htmlFor="hasta">
                                    Hasta <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <SeleccionarEmisor
                                    id="empresa"
                                    value={issuer}
                                    onChange={(e) => {
                                        dispatch(
                                            updateIssuer({
                                                issuer: e
                                            })
                                        );
                                    }}
                                    skeletonWidth={'30rem'}
                                    skeletonHeigh={'3rem'}
                                    onBlur={() => {}}
                                    placeHolder="Seleccione un emisor"
                                />
                                <label htmlFor="hasta">
                                    Seleccione Emisor <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <AutoFocusDropdown
                                    id="tipoDoc"
                                    value={tipoDE}
                                    options={TIPO_DOCUMENTOS_ELECTRONICOS}
                                    optionLabel="name"
                                    placeholder="Seleccione un tipo de documento electrónico"
                                    showClear
                                    onChange={(e) => {
                                        dispatch(
                                            updateTipoDE({
                                                tipoDE: e.value
                                            })
                                        );
                                    }}
                                />
                                <label htmlFor="tipoDoc">Tipo documento</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-content-end">
                        <Button type="button" className="field col-12 md:col-1" loading={loading} disabled={disablefilter} onClick={downloadFile} label="Descargar" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Ventas;
