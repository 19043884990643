import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import React, { useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { isEmpty } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';
import AutoFocusDropdown from '../../../components/AutoFocusDropdown';

const GCamDEAsoc = (props) => {
    const tipoDocumentoAsoc = [
        {
            i_tip_doc_aso: 1,
            d_des_tip_doc_aso: 'Electrónico'
        },
        {
            i_tip_doc_aso: 2,
            d_des_tip_doc_aso: 'Impreso'
        },
        {
            i_tip_doc_aso: 3,
            d_des_tip_doc_aso: 'Constancia Electrónica'
        }
    ];

    const tipoDocumentoImpreso = [
        {
            i_tipo_doc_aso: 1,
            dd_tipo_doc_aso: 'Factura'
        },
        {
            i_tip_doc_aso: 2,
            dd_tipo_doc_aso: 'Nota de crédito'
        },
        {
            i_tip_doc_aso: 3,
            dd_tipo_doc_aso: 'Nota de débito'
        },
        {
            i_tip_doc_aso: 4,
            dd_tipo_doc_aso: 'Nota de remisión'
        }
    ];

    const tipoConstancia = [
        { i_tip_cons: 1, d_des_tip_cons: 'Constancia de no ser contribuyente' },
        { i_tip_cons: 2, d_des_tip_cons: 'Constancia de microproductores' }
    ];

    const fechaEmisionImpRef = useRef(null);

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que identifican al documento asociado</b>
                </Divider>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <AutoFocusDropdown
                            id="tipoDocAsoc"
                            checkmark={true}
                            value={props.formik.values.tipo_doc_asoc}
                            onChange={(e) => props.formik.setFieldValue('tipo_doc_asoc', e.value)}
                            onBlur={() => props.formik.setFieldTouched('tipo_doc_asoc', true)}
                            options={tipoDocumentoAsoc}
                            optionLabel="d_des_tip_doc_aso"
                            placeholder="Seleccione el tipo de documento asociado"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_doc_asoc') })}
                        />
                        <label htmlFor="tipoDocAsoc">Tipo de documento asociado</label>
                        {props.getFormErrorMessage('tipo_doc_asoc')}
                    </FloatLabel>
                </div>
                {props.formik.values.tipo_doc_asoc?.i_tip_doc_aso === 1 && (
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <InputText
                                id="cdcDocAso"
                                value={props.formik.values.d_cd_cde_ref}
                                onChange={(e) => props.formik.setFieldValue('d_cd_cde_ref', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_cd_cde_ref', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_cd_cde_ref') })}
                            />
                            <label htmlFor="cdcDocAso">CDC del documento asociado</label>
                            {props.getFormErrorMessage('d_cd_cde_ref')}
                        </FloatLabel>
                    </div>
                )}
                {props.formik.values.tipo_doc_asoc?.i_tip_doc_aso === 2 && (
                    <>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText
                                    id="timbradoDocAso"
                                    value={props.formik.values.dn_tim_di}
                                    onChange={(e) => props.formik.setFieldValue('dn_tim_di', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('dn_tim_di', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('dn_tim_di') })}
                                />
                                <label htmlFor="timbradoDocAso">Nro. Timbrado</label>
                                {props.getFormErrorMessage('dn_tim_di')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText
                                    id="establecimientoDocAso"
                                    value={props.formik.values.d_est_doc_aso}
                                    onChange={(e) => props.formik.setFieldValue('d_est_doc_aso', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_est_doc_aso', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_est_doc_aso') })}
                                />
                                <label htmlFor="establecimientoDocAso">Establecimiento</label>
                                {props.getFormErrorMessage('d_est_doc_aso')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText
                                    id="puntoExpDocAso"
                                    value={props.formik.values.dp_exp_doc_aso}
                                    onChange={(e) => props.formik.setFieldValue('dp_exp_doc_aso', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('dp_exp_doc_aso', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('dp_exp_doc_aso') })}
                                />
                                <label htmlFor="puntoExpDocAso">Punto de expedición</label>
                                {props.getFormErrorMessage('dp_exp_doc_aso')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText
                                    id="nroDocAso"
                                    value={props.formik.values.d_num_doc_aso}
                                    onChange={(e) => props.formik.setFieldValue('d_num_doc_aso', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_num_doc_aso', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_doc_aso') })}
                                />
                                <label htmlFor="nroDocAso">Nro. Documento</label>
                                {props.getFormErrorMessage('d_num_doc_aso')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <AutoFocusDropdown
                                    id="tipoDocAsocImpreso"
                                    checkmark={true}
                                    value={props.formik.values.tipo_doc_asoc_impreso}
                                    onChange={(e) => props.formik.setFieldValue('tipo_doc_asoc_impreso', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('tipo_doc_asoc_impreso', true)}
                                    options={tipoDocumentoImpreso}
                                    optionLabel="dd_tipo_doc_aso"
                                    placeholder="Seleccione el tipo de documento asociado impreso"
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_doc_asoc_impreso') })}
                                />
                                <label htmlFor="tipoDocAsocImpreso">Tipo de documento impreso</label>
                                {props.getFormErrorMessage('tipo_doc_asoc_impreso')}
                            </FloatLabel>
                        </div>
                        {!isEmpty(props.formik.values.dn_tim_di) && (
                            <div className="field col-12 md:col-3">
                                <FloatLabel>
                                    <Calendar
                                        id="fechEmiImpreso"
                                        inputRef={fechaEmisionImpRef}
                                        value={props.formik.values.d_fec_emi_di}
                                        onChange={(e) => {
                                            fechaEmisionImpRef.current.focus();
                                            props.formik.setFieldValue('d_fec_emi_di', e.target.value);
                                        }}
                                        onFocus={() => props.formik.setFieldTouched('d_fec_emi_di', true)}
                                        dateFormat="yy-mm-dd"
                                        locale="es"
                                        selectionMode={'single'}
                                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_fec_emi_di') })}
                                    />
                                    <label htmlFor="fechEmiImpreso">Fecha de emisión del documento impreso</label>
                                    {props.getFormErrorMessage('d_fec_emi_di')}
                                </FloatLabel>
                            </div>
                        )}
                    </>
                )}

                {props.formik.values.tipo_doc_asoc?.i_tip_doc_aso === 3 && (
                    <>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <AutoFocusDropdown
                                    checkmark={true}
                                    id="tipoConstanciaAsoc"
                                    value={props.formik.values.tipo_constancia}
                                    onChange={(e) => props.formik.setFieldValue('tipo_constancia', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('tipo_constancia', true)}
                                    options={tipoConstancia}
                                    optionLabel="d_des_tip_cons"
                                    placeholder="Seleccione el tipo de constancia"
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_constancia') })}
                                />
                                <label htmlFor="tipoConstanciaAsoc">Tipo de constancia</label>
                                {props.getFormErrorMessage('tipo_constancia')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputNumber
                                    id="nroConstanciaDocAso"
                                    value={props.formik.values.d_num_cons}
                                    onChange={(e) => props.formik.setFieldValue('d_num_cons', e.value)}
                                    locale="es-PY"
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_cons') })}
                                    onBlur={() => props.formik.setFieldTouched('d_num_cons', true)}
                                />
                                <label htmlFor="nroConstanciaDocAso">Nro. Constancia</label>
                                {props.getFormErrorMessage('d_num_cons')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText
                                    id="nroControlConstanciaDocAso"
                                    value={props.formik.values.d_num_control}
                                    onChange={(e) => props.formik.setFieldValue('d_num_control', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_num_control', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_control') })}
                                />
                                <label htmlFor="nroControlConstanciaDocAso">Nro. de control de constancia</label>
                                {props.getFormErrorMessage('d_num_control')}
                            </FloatLabel>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default GCamDEAsoc;
