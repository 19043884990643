import { useHttpClient } from '../../hooks/useHttpClient';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SeleccionarEmisor from '../../components/SeleccionarEmisor';
import { isEmpty } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCDC, updateFromDate, updateIssuer, updateNumDoc, updateStatus, updateTipoDoc, updateToDate } from './reducer';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useMountEffect } from 'primereact/hooks';
import { FloatLabel } from 'primereact/floatlabel';
import { TIPO_DOCUMENTOS_ELECTRONICOS } from '../../utilities/constants';

const DocumentosElectronicos = () => {
    const httpClient = useHttpClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef(null);

    const [edocs, setEdocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from, setFromDate] = useState(new Date());
    const [to, setToDate] = useState(new Date());
    const [disablefilter, setDisableFilter] = useState(true);
    const [nroTotalFilas, setNroTotalFilas] = useState(0);
    const sortAsc = useRef(true);

    const fromDateState = useSelector((state) => state.edoc.fromDate);
    const toDateState = useSelector((state) => state.edoc.toDate);
    const issuer = useSelector((state) => state.edoc.issuer);
    const cdcState = useSelector((state) => state.edoc.cdc);
    const numDoc = useSelector((state) => state.edoc.numDoc);
    const selectedStatus = useSelector((state) => state.edoc.status);
    const selectedDocType = useSelector((state) => state.edoc.tipoDoc);

    const role = useSelector((state) => state.session.rol);

    const status = [
        {
            code: '0',
            name: 'EN_PROCESO'
        },
        {
            code: '1',
            name: 'APROBADO'
        },
        {
            code: '2',
            name: 'RECHAZADO'
        },
        {
            code: '3',
            name: 'CANCELADO'
        },
        {
            code: '4',
            name: 'ANULADO'
        }
    ];

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const issuerOnChange = (value) => {
        dispatch(
            updateIssuer({
                issuer: value
            })
        );
    };

    useEffect(() => {
        if (isEmpty(issuer.code)) {
            setDisableFilter(true);
            return;
        }
        if (from.getTime() > to.getTime()) {
            setDisableFilter(true);
            return;
        }
        setDisableFilter(false);
    }, [issuer, from, to]);

    useMountEffect(() => {
        if (!isEmpty(fromDateState)) {
            setFromDate(new Date(Date.parse(fromDateState)));
        } else {
            dispatch(
                updateFromDate({
                    fromDate: formatFromDate(from)
                })
            );
        }

        if (!isEmpty(toDateState)) {
            setToDate(new Date(Date.parse(toDateState)));
        } else {
            dispatch(
                updateToDate({
                    toDate: formatToDate(to)
                })
            );
        }

        if (!isEmpty(issuer.name)) {
            triggerLoad();
        }
    });

    const formatFromDate = (date: Date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        return format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
    };
    const formatToDate = (date: Date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
        return format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
    };

    const onSort = (e) => {
        sortAsc.current = !sortAsc.current;
        triggerLoad();
    };

    const formatDateForTable = (row) => {
        const date = Date.parse(row.fecha_emision);
        return format(date, 'dd-MM-yyyy HH:mm:ss');
    };

    const triggerLoad = () => {
        setLoading(true);

        const mapFilters = {};

        if (!isEmpty(cdcState)) {
            mapFilters['pk'] = `cdc:latest:${cdcState}`;
        }

        if (!isEmpty(numDoc)) {
            mapFilters['g_timb.d_num_doc'] = numDoc;
        }

        if (!isEmpty(selectedStatus?.name)) {
            mapFilters['estado'] = selectedStatus.name;
        }

        if (!isEmpty(selectedDocType?.name)) {
            mapFilters['g_timb.d_des_ti_de'] = selectedDocType.name;
        }

        const body = {
            filters: mapFilters,
            owner: issuer.code,
            from_date: fromDateState,
            to_date: toDateState,
            proj_names: ['pk', 'g_timb', 'g_dat_gral_ope.d_fe_emi_de', 'estado', 'g_dat_gral_ope.g_dat_rec'],
            sort_asc: sortAsc.current
        };
        httpClient
            .post('admin/listar/de/dynamo', body)
            .then((response) => {
                setEdocs(response.data.lista_de);
                setNroTotalFilas(response.data?.numero_filas || 0);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onBlur = (value) => {};

    const createEdoc = () => {
        navigate('/administracion/documentos/crear');
    };

    const header = () => {
        if (role === 'root' || role === 'admin') {
            return (
                <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span className="text-xl text-900 font-bold">Documentos Electrónicos</span>
                    <Button icon="pi pi-plus" onClick={() => createEdoc()} />
                </div>
            );
        }

        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">Documentos Electrónicos</span>
            </div>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex flex-wrap justify-content-start gap-1">
                <Button type="button" icon="pi pi-search" className="p-button-rounded p-button-info p-button-text mr-2 mb-2" tooltip="Ver detalle" onClick={() => navigate(`/administracion/documentos/${rowData.id}`)} />
                {rowData.estado === 'RECHAZADO' && (
                    <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-info p-button-text mr-2 mb-2" tooltip="Editar" onClick={() => navigate(`/administracion/documentos/actualizar/${rowData.id}`)} />
                )}
                {/*<DescargarKuDE edoc={rowData} setLoading={setLoading} className="p-button-rounded p-button-danger p-button-text mr-2 mb-2" />*/}
            </div>
        );
    };

    const footer = () => {
        const docWording = nroTotalFilas > 1 ? 'documentos' : 'documento';
        const findWording = nroTotalFilas > 1 ? 'encontrados' : 'encontrado';
        return `${nroTotalFilas} ${docWording} ${findWording}`;
    };

    return (
        <div className="grid table-demo">
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="col-12">
                <div className="card">
                    <h5>Filtros</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Calendar
                                    id="desde"
                                    value={from}
                                    onChange={(e) => {
                                        setFromDate(e.value);
                                        dispatch(
                                            updateFromDate({
                                                fromDate: formatFromDate(e.value)
                                            })
                                        );
                                    }}
                                    dateFormat="dd-mm-yy"
                                    showButtonBar={true}
                                    locale="es"
                                    selectionMode={'single'}
                                />
                                <label htmlFor="desde">
                                    Desde <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Calendar
                                    id="hasta"
                                    value={to}
                                    onChange={(e) => {
                                        setToDate(e.value);
                                        dispatch(
                                            updateToDate({
                                                toDate: formatToDate(e.value)
                                            })
                                        );
                                    }}
                                    dateFormat="dd-mm-yy"
                                    showButtonBar={true}
                                    selectionMode={'single'}
                                    locale="es"
                                />
                                <label htmlFor="hasta">
                                    Hasta <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <SeleccionarEmisor id="empresa" value={issuer} onChange={issuerOnChange} onBlur={onBlur} skeletonWidth={'30rem'} skeletonHeigh={'3rem'} placeHolder="Seleccione un emisor" />
                                <label htmlFor="hasta">
                                    Seleccione Emisor <span className="pink-color"> *</span>
                                </label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText
                                    id="cdc"
                                    value={cdcState}
                                    onChange={(e) => {
                                        dispatch(
                                            updateCDC({
                                                cdc: e.target.value
                                            })
                                        );
                                    }}
                                />
                                <label htmlFor="cdc">CDC</label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText
                                    id="numDoc"
                                    value={numDoc}
                                    onChange={(e) => {
                                        dispatch(
                                            updateNumDoc({
                                                numDoc: e.target.value
                                            })
                                        );
                                    }}
                                />
                                <label htmlFor="numDoc">Número comprobante</label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                    id="estado"
                                    value={selectedStatus}
                                    options={status}
                                    optionLabel="name"
                                    placeholder="Seleccione el estado de la factura"
                                    showClear
                                    onChange={(e) => {
                                        dispatch(
                                            updateStatus({
                                                status: e.value
                                            })
                                        );
                                    }}
                                />
                                <label htmlFor="estado">Estado</label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                    id="tipoDoc"
                                    value={selectedDocType}
                                    options={TIPO_DOCUMENTOS_ELECTRONICOS}
                                    optionLabel="name"
                                    placeholder="Seleccione un tipo de documento electrónico"
                                    showClear
                                    onChange={(e) => {
                                        dispatch(
                                            updateTipoDoc({
                                                tipoDoc: e.value
                                            })
                                        );
                                    }}
                                />
                                <label htmlFor="tipoDoc">Tipo documento</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-content-end">
                        <Button type="button" className="field col-12 md:col-1" disabled={disablefilter} onClick={triggerLoad} label="Filtrar" />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <DataTable
                        value={edocs}
                        header={header}
                        footer={footer}
                        onSort={onSort}
                        className="mt-3"
                        id={'edocsDataTable'}
                        dataKey={'id'}
                        scrollable={true}
                        scrollHeight="450px"
                        responsiveLayout="stack"
                        stripedRows
                        loading={loading}
                        emptyMessage="No existen documentos electrónicos"
                        virtualScrollerOptions={{
                            id: 'id',
                            itemSize: 46,
                            autoSize: true
                            // delay: 200
                            // showLoader: true
                            // loadingTemplate: loadingTemplate
                        }}
                    >
                        <Column field="fecha_emision" header="Fecha Emisión" sortable style={{ minWidth: '9rem' }} body={formatDateForTable} />
                        {/*<Column field="id" header="CDC" filterPlaceholder="Filtrar por CDC" style={{ maxWidth: '22rem' }} />*/}
                        <Column field="nro_doc" header="Nro Documento" style={{ minWidth: '6rem' }} />
                        <Column field="desc_tipo_de" header="Tipo" style={{ minWidth: '6rem' }} />
                        <Column field="receptor" header="Receptor" style={{ minWidth: '15rem' }} />
                        <Column field="estado" header="Estado" style={{ minWidth: '5rem' }} />
                        <Column header="Acciones" style={{ flex: '0 0 2rem', minWidth: '4rem' }} body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default DocumentosElectronicos;
