import { createSlice } from '@reduxjs/toolkit';

export const reporteReducer = createSlice({
    name: 'reporte',
    initialState: {
        fromDate: new Date(),
        toDate: new Date(),
        tipoDE: {},
        issuer: { code: '', name: '' }
    },
    reducers: {
        updateFromDate: (state, action) => {
            state.fromDate = action.payload.fromDate;
        },
        updateToDate: (state, action) => {
            state.toDate = action.payload.toDate;
        },
        updateTipoDE: (state, action) => {
            state.tipoDE = action.payload.tipoDE;
        },
        updateIssuer: (state, action) => {
            state.issuer = action.payload.issuer;
        }
    }
});

export const { updateIssuer, updateToDate, updateFromDate, updateTipoDE } = reporteReducer.actions;
