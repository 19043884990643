import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { Skeleton } from 'primereact/skeleton';
import AutoFocusDropdown from './AutoFocusDropdown';

const SeleccionarDistrito = (props) => {
    const httpClient = useHttpClient();
    const [distritos, setDistritos] = useState([]);
    const [currentDpto, setCurrentDpto] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.departamento && props.departamento !== currentDpto) {
            setCurrentDpto(props.departamento);
            setIsLoading(true);
            httpClient
                .get(`api/distritos/${props.departamento}`)
                .then((response) => {
                    setDistritos(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.departamento, currentDpto]);

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        return (
            <AutoFocusDropdown
                id={props.id}
                filter
                emptyMessage="No se econtraron distritos, por favor selecione otro departamento"
                value={props.value}
                onChange={(e) => props.onChange(e)}
                onBlur={() => props.onBlur()}
                options={distritos}
                checkmark={true}
                optionLabel="nombre"
                placeholder="Seleccionar Distrito"
                className={props.className}
                virtualScrollerOptions={{ itemSize: 38 }}
            />
        );
    };

    return getSelect();
};

export default SeleccionarDistrito;
