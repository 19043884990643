import { Divider } from 'primereact/divider';
import React, { useEffect, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { addLocale } from 'primereact/api';
import { AFECTACION_TRIBUTARIA_EXENTO, AFECTACION_TRIBUTARIA_EXONERADO, AFECTACION_TRIBUTARIA_GRABADO_PARCIAL, AFECTACION_TRIBUTARIA_GRAVADO_IVA } from '../../../../utilities/constants';
import { isEmpty } from 'lodash';
import { useHttpClient } from '../../../../hooks/useHttpClient';
import { classNames } from 'primereact/utils';
import { FloatLabel } from 'primereact/floatlabel';
import { useUpdateEffect } from 'primereact/hooks';
import { convertItemsToEdocFormat, convertItemsToFormikFormat } from '../utils/items';
import AutoFocusDropdown from '../../../../components/AutoFocusDropdown';

const GCamItem = (props) => {
    const httpClient = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    //Campos del item
    const [codProd, setCodProd] = useState('');
    const [codDNCPGral, setCodDNCPGral] = useState('');
    const [codDNCPEsp, setCodDNCPEsp] = useState('');
    const [descProd, setDescProd] = useState('');
    const [unidadMedida, setUnidadMedida] = useState({ c_uni_med: 77, d_des_uni_med: 'UNI' });
    const [cantidad, setCantidad] = useState(0);
    const [precioUnitario, setPrecioUnitario] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [monedaOperacion, setMonedaOperacion] = useState('PYG');

    const [invalidCodProd, setInvalidCodProd] = useState(true);
    const [invalidDescProd, setInvalidDescProd] = useState(true);
    const [invalidCantidad, setInvalidCantidad] = useState(true);

    //Campos para el cálculo del IVA y totales
    const [formaAfectIVA, setFormaAFectIVA] = useState({});
    const [proporcionIVA, setProporcionIVA] = useState(0);
    const [tasaIVA, setTasaIVA] = useState(0);
    const [totalIVA5, setTotalIVA5] = useState(0);
    const [totalIVA10, setTotalIVA10] = useState(0);
    const [totalIVA, setTotalIVA] = useState(0);
    const [totalOp, setTotalOp] = useState(0);
    const [totalExe, setTotalExe] = useState(0);

    useEffect(() => {
        const moneda = props.formik.values.moneda_operacion?.c_mone_ope;
        if (!isEmpty(moneda)) {
            setMonedaOperacion(moneda);
        }
    }, [props.formik.values.moneda_operacion?.c_mone_ope]);

    useEffect(() => {
        if (isEmpty(codProd)) {
            setInvalidCodProd(true);
        } else {
            setInvalidCodProd(false);
        }

        if (isEmpty(descProd)) {
            setInvalidDescProd(true);
        } else {
            setInvalidDescProd(false);
        }

        if (cantidad > 0) {
            setInvalidCantidad(false);
        } else {
            setInvalidCantidad(true);
        }
    }, [codProd, descProd, cantidad]);

    const invalidItem = useMemo(() => {
        return invalidCantidad || invalidDescProd || invalidCodProd;
    }, [invalidCantidad, invalidDescProd, invalidCodProd]);

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const guarani = new Intl.NumberFormat('es-PY', {
        style: 'currency',
        currency: 'PYG',
        maximumFractionDigits: 0
    });

    const guaraniWithFractionDigits = new Intl.NumberFormat('es-PY', {
        style: 'currency',
        currency: 'PYG',
        maximumFractionDigits: 4
    });

    const usdDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    });

    // const listaUnidadMedida = [
    //     {
    //         c_uni_med: 77,
    //         d_des_uni_med: 'UNI'
    //     }
    // ];

    const listaAfectacionIVA = [
        {
            i_afec_iva: 1,
            d_des_afec_iva: 'Gravado IVA'
        },
        {
            i_afec_iva: 2,
            d_des_afec_iva: 'Exonerado (Art. 100 - Ley 6380/2019)'
        },
        {
            i_afec_iva: 3,
            d_des_afec_iva: 'Exento'
        },
        {
            i_afec_iva: 4,
            d_des_afec_iva: 'Gravado parcial (Grav-Exento)'
        }
    ];

    const listaProporcionIVA = [50, 30];

    const listaTasaIVA = [5, 10];

    useUpdateEffect(() => {
        if (props.isEditing && !props.fieldsLoading) {
            const items = convertItemsToEdocFormat(props.formik.values?.g_cam_item);

            let descuentoGlobal = 0;

            if (props.formik.values.cond_ope?.i_cond_ope === 1 && props.formik.values.tipo_pago?.i_ti_pago === 11) {
                descuentoGlobal = props.formik.values.d_mon_ti_pag;
            }

            // Significa que la factura es a crédito, pero hay un monto de entrega inicial
            if (props.formik.values.d_mon_ent > 0) {
                descuentoGlobal = props.formik.values.d_mon_ent;
            }

            const body = {
                emisor: props.formik.values?.g_emis?.ruc?.code,
                tipo_de: props.formik.values?.tipo_de?.i_ti_de,
                tipo_operacion: props.formik.values.tipo_op_rec?.i_ti_ope,
                tipo_impuesto_afectado: props.formik.values.tipo_imp_afectado?.i_timp,
                moneda_operacion: props.formik.values.moneda_operacion?.c_mone_ope,
                tipo_de_cambio: props.formik.values.d_ti_cam,
                cond_tipo_cambio: props.formik.values.condicion_tipo_cambio?.d_cond_ti_cam || 0,
                items: items,
                descuent_global: descuentoGlobal
            };

            httpClient
                .post('admin/de/totales', body)
                .then((response) => {
                    setIsLoading(false);
                    setTotalIVA5(response.data.totales.d_iva_5);
                    setTotalIVA10(response.data.totales.d_iva_10);
                    setTotalIVA(response.data.totales.d_tot_iva);
                    setTotalOp(response.data.totales.d_tot_gral_ope);
                    setTotalExe(response.data.totales.d_sub_exe);
                    setIsLoading(false);
                    props.formik.setFieldValue('g_cam_item', convertItemsToFormikFormat(response.data.items));
                })
                .catch((error) => {
                    props.onError(error);
                    setIsLoading(false);
                });
        }
    }, [props.isEditing, props.fieldsLoading]);

    const agregarItem = () => {
        let gCamItem = {
            d_cod_int: codProd,
            d_dncp_g: codDNCPGral,
            d_dncp_e: codDNCPEsp,
            d_des_pro_ser: descProd,
            unidad_medida: unidadMedida,
            d_cant_pro_ser: cantidad,
            c_uni_med: unidadMedida.c_uni_med,
            d_des_uni_med: unidadMedida.d_des_uni_med
        };

        //Solamente se calculan los valores del item, cuando no es una nota de remisión electrónica
        if (props.formik.values.tipo_de?.i_ti_de !== 7) {
            setIsLoading(true);
            const tipoImpAfectado = props.formik.values.tipo_imp_afectado?.i_timp;
            let d_tot_bru_ope_item = 0;

            if (tipoImpAfectado === 1 || tipoImpAfectado === 3 || tipoImpAfectado === 4 || tipoImpAfectado === 5) {
                d_tot_bru_ope_item = precioUnitario * cantidad;
            }

            const tipoDE = props.formik.values.tipo_de?.i_ti_de;

            if (tipoDE === 4) {
                d_tot_bru_ope_item = precioUnitario * cantidad;
            }

            const dTotOpeItem = (precioUnitario - descuento) * cantidad;

            gCamItem.g_valor_item = {
                d_p_uni_pro_ser: precioUnitario,
                d_tot_bru_ope_item: d_tot_bru_ope_item,
                g_valor_resta_item: {
                    d_desc_item: descuento,
                    d_tot_ope_item: dTotOpeItem, //TODO: Apenas soportemos descuentos y/o anticipos debemos actualizar esta formula
                    d_tot_ope_gs: 0
                }
            };

            gCamItem.g_cam_iva = {
                afectacion_tributaria: formaAfectIVA,
                i_afec_iva: formaAfectIVA.i_afec_iva,
                d_des_afec_iva: formaAfectIVA.d_des_afec_iva,
                d_prop_iva: proporcionIVA,
                d_tasa_iva: tasaIVA
            };

            if (formaAfectIVA.i_afec_iva === 2 || formaAfectIVA.i_afec_iva === 3) {
                gCamItem.g_cam_iva.d_prop_iva = 0;
                gCamItem.g_cam_iva.d_tasa_iva = 0;
            }

            if (formaAfectIVA.i_afec_iva === 1) {
                gCamItem.g_cam_iva.d_prop_iva = 100;
            }

            const items = convertItemsToEdocFormat([...(props.formik.values?.g_cam_item || []), gCamItem]);

            let descuentoGlobal = 0;

            if (props.formik.values.cond_ope?.i_cond_ope === 1 && props.formik.values.tipo_pago?.i_ti_pago === 11) {
                descuentoGlobal = props.formik.values.d_mon_ti_pag;
            }

            // Significa que la factura es a crédito, pero hay un monto de entrega inicial
            if (props.formik.values.d_mon_ent > 0) {
                descuentoGlobal = props.formik.values.d_mon_ent;
            }

            const body = {
                emisor: props.formik.values.g_emis.ruc.code,
                tipo_de: props.formik.values.tipo_de.i_ti_de,
                tipo_operacion: props.formik.values.tipo_op_rec.i_ti_ope,
                tipo_impuesto_afectado: props.formik.values.tipo_imp_afectado.i_timp,
                moneda_operacion: props.formik.values.moneda_operacion.c_mone_ope,
                tipo_de_cambio: props.formik.values.d_ti_cam,
                cond_tipo_cambio: props.formik.values.condicion_tipo_cambio?.d_cond_ti_cam || 0,
                items: items,
                descuento_global: descuentoGlobal
            };

            httpClient
                .post('admin/de/totales', body)
                .then((response) => {
                    setIsLoading(false);
                    setTotalIVA5(response.data.totales?.d_iva_5);
                    setTotalIVA10(response.data.totales?.d_iva_10);
                    setTotalIVA(response.data.totales?.d_tot_iva);
                    setTotalOp(response.data.totales?.d_tot_gral_ope);
                    setTotalExe(response.data.totales?.d_sub_exe);
                    props.formik.setFieldValue('g_cam_item', convertItemsToFormikFormat(response.data.items));
                    setIsLoading(false);
                })
                .catch((error) => {
                    props.onError(error);
                    setIsLoading(false);
                });
        } else {
            const items = [...(props.formik.values?.g_cam_item || []), gCamItem];
            props.formik.setFieldValue('g_cam_item', items);
        }
        limpiarCampos();
    };

    const limpiarCampos = () => {
        setCodProd('');
        setCodDNCPGral('');
        setCodDNCPEsp('');
        setDescProd('');
        setUnidadMedida({ c_uni_med: 77, d_des_uni_med: 'UNI' });
        setCantidad(0);
        setPrecioUnitario(0);
        setDescuento(0);
        setFormaAFectIVA({});
        setProporcionIVA(0);
        setTasaIVA(0);
        // setValorTotalExenta(0);
        // setValorTotalCinco(0);
        // setValorTotalDiez(0);
        // setValorTotal(0);
    };

    const eliminarItem = (val) => {
        let items = [];
        const originalItems = [...props.formik.values.g_cam_item];

        for (let i = 0; i < originalItems.length; i++) {
            if (val.d_cod_int !== originalItems[i].d_cod_int) {
                items = [...items, originalItems[i]];
            }
        }

        if (items.length === 0) {
            props.formik.setFieldValue('g_cam_item', items);
            setTotalIVA5(0);
            setTotalIVA10(0);
            setTotalIVA(0);
            setTotalOp(0);
            return;
        }

        //Solamente se calculan los valores del item, cuando no es una nota de remisión electrónica
        if (props.formik.values.tipo_de?.i_ti_de !== 7) {
            setIsLoading(true);

            const tmpItems = convertItemsToEdocFormat(items);

            let descuentoGlobal = 0;

            if (props.formik.values.cond_ope?.i_cond_ope === 1 && props.formik.values.tipo_pago?.i_ti_pago === 11) {
                descuentoGlobal = props.formik.values.d_mon_ti_pag;
            }

            // Significa que la factura es a crédito, pero hay un monto de entrega inicial
            if (props.formik.values.d_mont_ent > 0) {
                descuentoGlobal = props.formik.values.d_mont_ent;
            }

            const body = {
                emisor: props.formik.values.g_emis.ruc.code,
                tipo_de: props.formik.values.tipo_de.i_ti_de,
                tipo_operacion: props.formik.values.tipo_op_rec.i_ti_ope,
                tipo_impuesto_afectado: props.formik.values.tipo_imp_afectado.i_timp,
                moneda_operacion: props.formik.values.moneda_operacion.c_mone_ope,
                tipo_de_cambio: props.formik.values.d_ti_cam,
                items: tmpItems,
                descuento_global: descuentoGlobal
            };

            httpClient
                .post('admin/de/totales', body)
                .then((response) => {
                    setIsLoading(false);
                    setTotalIVA5(response.data.totales.d_iva_5);
                    setTotalIVA10(response.data.totales.d_iva_10);
                    setTotalIVA(response.data.totales.d_tot_iva);
                    setTotalOp(response.data.totales.d_tot_gral_ope);
                    setTotalExe(response.data.totales.d_sub_exe);
                    props.formik.setFieldValue('g_cam_item', convertItemsToFormikFormat(response.data.items));
                    setIsLoading(false);
                })
                .catch((error) => {
                    props.onError(error);
                    setIsLoading(false);
                });
        }

        props.formik.setFieldValue('g_cam_item', items);
        limpiarCampos();
    };

    const unitPriceVal = (item) => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(item.g_valor_item.d_p_uni_pro_ser);
        }
        return usdDollar.format(item.g_valor_item.d_p_uni_pro_ser);
    };

    //Se deben sumar ambos descuentos, el descuento global por item,
    // o el descuento particular por item
    const descuentoUnitario = (item) => {
        if (monedaOperacion === 'PYG') {
            return guaraniWithFractionDigits.format(item.g_valor_item.g_valor_resta_item.d_desc_item + item.g_valor_item.g_valor_resta_item.d_desc_glo_item);
        }
        return usdDollar.format(item.g_valor_item.g_valor_resta_item.d_desc_item + item.g_valor_item.g_valor_resta_item.d_desc_glo_item);
    };

    const val5 = (item) => {
        let val = 0;
        if (item.g_cam_iva.d_tasa_iva === 5) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }
        if (monedaOperacion === 'PYG') {
            return guarani.format(val);
        }
        return usdDollar.format(val);
    };

    const val10 = (item) => {
        let val = 0;
        if (item.g_cam_iva.d_tasa_iva === 10) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }

        if (monedaOperacion === 'PYG') {
            return guarani.format(val);
        }
        return usdDollar.format(val);
    };
    const exentasVal = (item) => {
        let val = 0;
        if (item.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_EXONERADO || item?.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_EXENTO) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }
        if (monedaOperacion === 'PYG') {
            return guarani.format(val);
        }
        return usdDollar.format(val);
    };

    const valTotExentas = () => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(totalExe);
        }
        return usdDollar.format(totalExe);
    };

    const valTot5IVA = () => {
        let val = 0;
        props.formik.values.g_cam_item?.forEach((item) => {
            if ((item.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_GRAVADO_IVA || item.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_GRABADO_PARCIAL) && item.g_cam_iva?.d_tasa_iva === 5) {
                val += item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
            }
        });

        if (monedaOperacion === 'PYG') {
            return guarani.format(val);
        }
        return usdDollar.format(val);
    };
    const valTot10IVA = () => {
        let val = 0;
        props.formik.values.g_cam_item?.forEach((item) => {
            if ((item.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_GRAVADO_IVA || item.g_cam_iva?.afectacion_tributaria?.i_afec_iva === AFECTACION_TRIBUTARIA_GRABADO_PARCIAL) && item.g_cam_iva?.d_tasa_iva === 10) {
                val += item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
            }
        });

        if (monedaOperacion === 'PYG') {
            return guarani.format(val);
        }
        return usdDollar.format(val);
    };

    const valTotGralOpe = () => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(totalOp);
        }
        return usdDollar.format(totalOp);
    };

    const valLiqIVA5 = () => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(totalIVA5);
        }
        return usdDollar.format(totalIVA5);
    };
    const valLiqIVA10 = () => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(totalIVA10);
        }
        return usdDollar.format(totalIVA10);
    };

    const valTotIVA = () => {
        if (monedaOperacion === 'PYG') {
            return guarani.format(totalIVA);
        }
        return usdDollar.format(totalIVA);
    };

    const actionTemplate = (rowData) => {
        return <Button id="eliminar" type="button" icon="pi pi-times" severity="danger" onClick={() => eliminarItem(rowData)} />;
    };

    const headerGroup = (
        <ColumnGroup>
            {props.formik.values.tipo_de?.i_ti_de !== 7 ? (
                <Row>
                    <Column header="Acciones" />
                    <Column header="Código" field="d_cod_int" />
                    <Column header="Descripción" field="d_des_pro_ser" />
                    <Column header="Cantidad" field="d_cant_pro_ser" />
                    <Column header="Precio unitario" />
                    <Column header="Descuento" />
                    <Column header="Exentas" />
                    <Column header="5%" />
                    <Column header="10%" />
                </Row>
            ) : (
                <Row>
                    <Column header="Acciones" />
                    <Column header="Código" field="d_cod_int" />
                    <Column header="Descripción" field="d_des_pro_ser" />
                    <Column header="Cantidad" field="d_cant_pro_ser" />
                </Row>
            )}
        </ColumnGroup>
    );

    const footerGroup = () => {
        if (props.formik.values.tipo_de?.i_ti_de !== 7) {
            return (
                <ColumnGroup>
                    <Row>
                        <Column footer="Subtotal: " colSpan={6} />
                        <Column footer={valTotExentas} />
                        <Column footer={valTot5IVA} />
                        <Column footer={valTot10IVA} />
                    </Row>
                    <Row>
                        <Column footer="Total de la operación: " colSpan={8} />
                        <Column footer={valTotGralOpe} />
                    </Row>
                    <Row>
                        <Column footer="Liquidación IVA: " />
                        <Column footer={`(5%): ${valLiqIVA5()}`} colSpan={2} />
                        <Column footer={`(10%): ${valLiqIVA10()}`} colSpan={2} />
                        <Column footer={`Total IVA: ${valTotIVA()}`} colSpan={4} />
                    </Row>
                </ColumnGroup>
            );
        }
        return null;
    };

    const getFormErrorMessage = (val) => {
        return val ? <small className="p-error">{'Campo requerido'}</small> : <small className="p-error">&nbsp;</small>;
    };

    const renderTableItems = () => {
        return (
            <DataTable value={props.formik.values.g_cam_item} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup()} loading={isLoading}>
                <Column header="Acciones" body={actionTemplate}></Column>
                <Column header="Código" field="d_cod_int" />
                <Column header="Descripción" field="d_des_pro_ser" />
                <Column header="Cantidad" field="d_cant_pro_ser" />
                {props.formik.values.tipo_de?.i_ti_de !== 7 && <Column header="Precio unitario" body={unitPriceVal} />}
                {props.formik.values.tipo_de?.i_ti_de !== 7 && <Column header="Descuento" body={descuentoUnitario} />}
                {props.formik.values.tipo_de?.i_ti_de !== 7 && <Column header="Exentas" body={exentasVal} />}
                {props.formik.values.tipo_de?.i_ti_de !== 7 && <Column header="5%" body={val5} />}
                {props.formik.values.tipo_de?.i_ti_de !== 7 && <Column header="10%" body={val10} />}
            </DataTable>
        );
    };

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Detalles de la facturación</b>
                </Divider>

                <div className="field col-12 md:col-1">
                    <FloatLabel>
                        <InputText id="codigoProducto" type="text" value={codProd} onChange={(e) => setCodProd(e.target.value)} className={classNames({ 'p-invalid': invalidCodProd })} />
                        <label htmlFor="codigoProducto">Código</label>
                        {getFormErrorMessage(invalidCodProd)}
                    </FloatLabel>
                </div>
                {props.formik.values.tipo_op_rec?.i_ti_ope === 3 && (
                    <>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText id="codDNCPGral" type="text" value={codDNCPGral} onChange={(e) => setCodDNCPGral(e.target.value)} />
                                <label htmlFor="codDNCPGral">Cod DNCP - Nivel General</label>
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <InputText id="codDNCPEsp" type="text" value={codDNCPEsp} onChange={(e) => setCodDNCPEsp(e.target.value)} />
                                <label htmlFor="codDNCPEsp">Cod DNCP - Nivel Específico</label>
                            </FloatLabel>
                        </div>
                    </>
                )}
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText id="descProducto" type="text" value={descProd} onChange={(e) => setDescProd(e.target.value)} className={classNames({ 'p-invalid': invalidDescProd })} />
                        <label htmlFor="descProducto">Descripción</label>
                        {getFormErrorMessage(invalidDescProd)}
                    </FloatLabel>
                </div>
                {/*<div className="field col-12 md:col-2">*/}
                {/*    <label htmlFor="uMedida">Unidad de medida</label>*/}
                {/*    <Dropdown id="uMedida" value={unidadMedida} onChange={(e) => setUnidadMedida(e.value)} options={listaUnidadMedida} optionLabel="d_des_uni_med" placeholder="Seleccione la unidad de medida" />*/}
                {/*</div>*/}
                <div className="field col-12 md:col-1">
                    <FloatLabel>
                        <InputNumber id="cantidad" value={cantidad} onChange={(e) => setCantidad(e.value)} locale="es-PY" className={classNames({ 'p-invalid': invalidCantidad })} />
                        <label htmlFor="cantidad">Cantidad</label>
                        {getFormErrorMessage(invalidCantidad)}
                    </FloatLabel>
                </div>
                {props.formik.values.tipo_de?.i_ti_de !== 7 && (
                    <>
                        <div className="field col-12 md:col-1">
                            <FloatLabel>
                                {monedaOperacion === 'PYG' && <InputNumber id="precioUnitario" locale="es-PY" value={precioUnitario} onValueChange={(e) => setPrecioUnitario(e.value)} />}
                                {monedaOperacion === 'USD' && <InputNumber id="precioUnitario" locale="en-US" maxFractionDigits={2} value={precioUnitario} onValueChange={(e) => setPrecioUnitario(e.value)} />}
                                <label htmlFor="precioUnitario">Precio</label>
                            </FloatLabel>
                        </div>
                        {/*<div className="field col-12 md:col-1">*/}
                        {/*    <FloatLabel>*/}
                        {/*        {monedaOperacion === 'PYG' && <InputNumber id="descuento" locale="es-PY" value={descuento} maxFractionDigits={8} onValueChange={(e) => setDescuento(e.value)} />}*/}
                        {/*        {monedaOperacion === 'USD' && <InputNumber id="descuento" locale="en-US" maxFractionDigits={2} value={descuento} onValueChange={(e) => setDescuento(e.value)} />}*/}
                        {/*        <label htmlFor="descuento">Descuento</label>*/}
                        {/*    </FloatLabel>*/}
                        {/*</div>*/}
                        <div className="field col-12 md:col-2">
                            <FloatLabel>
                                <AutoFocusDropdown
                                    id="formAfecIVA"
                                    value={formaAfectIVA}
                                    checkmark={true}
                                    onChange={(e) => setFormaAFectIVA(e.value)}
                                    options={listaAfectacionIVA}
                                    optionLabel="d_des_afec_iva"
                                    placeholder="Seleccione la forma de afectación tributaria del IVA"
                                />
                                <label htmlFor="formAfecIVA">Afect. IVA</label>
                            </FloatLabel>
                        </div>
                        {formaAfectIVA.i_afec_iva !== 2 && formaAfectIVA.i_afec_iva !== 3 && (
                            <>
                                {formaAfectIVA.i_afec_iva === 4 && (
                                    <div className="field col-12 md:col-2">
                                        <FloatLabel>
                                            <AutoFocusDropdown
                                                id="proporcionIVA"
                                                checkmark={true}
                                                value={proporcionIVA}
                                                onChange={(e) => setProporcionIVA(e.value)}
                                                options={listaProporcionIVA}
                                                placeholder="Seleccione la proporción gravada del IVA"
                                            />
                                            <label htmlFor="proporcionIVA">Proporción Gravada</label>
                                        </FloatLabel>
                                    </div>
                                )}
                                <div className="field col-12 md:col-1">
                                    <FloatLabel>
                                        <AutoFocusDropdown id="tasaIVA" checkmark={true} value={tasaIVA} onChange={(e) => setTasaIVA(e.value)} options={listaTasaIVA} placeholder="Seleccione la tasa del IVA" />
                                        <label htmlFor="tasaIVA">Tasa IVA</label>
                                    </FloatLabel>
                                </div>
                            </>
                        )}
                    </>
                )}
                <div className="col-12 md:col-1  justify-content-end" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button id="agregar" type="button" icon="pi pi-check" style={{ marginBottom: '3.5rem' }} disabled={invalidItem} onClick={() => agregarItem()} />
                </div>
            </div>
            {renderTableItems()}
        </>
    );
};
export default GCamItem;
