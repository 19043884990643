import { useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';

const AutoFocusDropdown = (props) => {
    const ref = useRef(null);

    return (
        <Dropdown
            id={props.id}
            inputRef={ref}
            value={props.value}
            onChange={(e) => {
                ref.current.focus();
                props.onChange(e);
            }}
            onBlur={(e) => {
                if (props.onBlur) {
                    props.onBlur();
                }
            }}
            options={props.options}
            checkmark={props.checkmark}
            optionLabel={props.optionLabel}
            showClear={props.showClear}
            placeholder={props.placeholder}
            className={props.className}
            virtualScrollerOptions={props.virtualScrollerOptions}
            filter={props.filter}
            itemTemplate={props.itemTemplate}
            valueTemplate={props.valueTemplate}
        />
    );
};

export default AutoFocusDropdown;
