import React, { useRef, useState } from 'react';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { setLocale } from 'yup';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import SeleccionarEmisor from '../../../components/SeleccionarEmisor';
import '../divider.css';
import GOpeCom from './GOpeCom';
import GDatRec from './GDatRec';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { es } from 'yup-locales';
import { get, isEmpty } from 'lodash';
import GDTipDE from './CamposPorTipoDeDocumento/GDTipDE';
import SeleccionarTimbrado from '../../../components/SeleccionarTimbrado';
import validationSchema from './validator';
import { format } from 'date-fns';
import GCamDEAsoc from './GCamDEAsoc';
import { FloatLabel } from 'primereact/floatlabel';
import { useMountEffect } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { NATURALEZA_RECEPTOR, TIPO_CONTRIBUYENTE_RECEPTOR, TIPO_OPERACION_RECEPTOR } from './constants/receptor';
import { LISTA_FORMA_PROC_PAGO } from './constants/condicion_operacion';
import { CONDICION_NEGOCIACION, NATURALEZA_TRANSPORTISTA, RESPONSABLE_COSTO_FLETE, TIPO_IDENTIFICACION_VEHICULO } from './constants/transporte_mercaderias';
import AutoFocusDropdown from '../../../components/AutoFocusDropdown';
import { LISTA_DE } from './constants/de';

const CrearDocumentoElectronico = (props) => {
    const httpClient = useHttpClient();
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [emisor, setEmisor] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const { cdc } = useParams();
    const [timbrado, setTimbrado] = useState({});

    setLocale(es);

    useMountEffect(() => {
        if (!isEmpty(cdc)) {
            setIsLoading(true);
            setIsEditing(true);
            httpClient
                .get(`api/de/${cdc}`)
                .then((response) => {
                    fillFormik(response.data);
                    setTimbrado(response.data.g_timb);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    navigate('/administracion/documentos');
                });
        }
    });

    const issuerOnChange = (value) => {
        formik.setFieldValue('g_emis.ruc', value);
        setIsLoading(true);
        httpClient
            .get(`admin/emisor/${value.code}`)
            .then((response) => {
                setEmisor(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const issuerOnBlur = (value) => {
        formik.setFieldTouched('g_emis.ruc', value);
    };

    const onSubmit = (values) => {
        setIsLoading(true);
        const de = generarDE(values);

        httpClient
            .post('api/de/async', de)
            .then((response) => {
                navigate('/administracion/documentos');
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error al crear el documento electrónico', detail: error.response.data.error, life: 10000 });
                setIsLoading(false);
                if (error.response?.data?.error === 'SIFEN no responde. Favor contactar con la DNIT') {
                    navigate('/administracion/documentos');
                }
            });
    };

    const formatToStr = (date: Date) => {
        return format(date, 'yyyy-MM-dd');
    };

    const formatIssueDateToStr = (date: Date) => {
        return format(date, "yyyy-MM-dd'T'HH:mm:ss");
    };

    const generarDE = (values) => {
        let de = {
            g_ope_de: {
                i_tip_emi: 1
            },
            g_timb: {},
            g_dat_gral_ope: {
                g_emis: {},
                g_dat_rec: {}
            },
            g_d_tip_de: {},
            async: true
        };

        de.g_dat_gral_ope.d_fe_emi_de = formatIssueDateToStr(values.d_fe_emi_de);

        if (isEditing) {
            de.g_timb = { ...timbrado };
        } else {
            //1- Generar Timbrado
            de.g_timb.i_ti_de = values.tipo_de.i_ti_de;
            de.g_timb.d_num_tim = values.timbrado.nro_timbrado;
            de.g_timb.d_est = values.timbrado.establecimiento.toString().padStart(3, '0');
            de.g_timb.d_pun_exp = values.timbrado.punto_expedicion.toString().padStart(3, '0');
        }

        //2- Generar g_ope_com
        if (de.g_timb.i_ti_de !== 7) {
            de.g_dat_gral_ope.g_ope_com = {};

            if (de.g_timb.i_ti_de === 1 || de.g_timb.i_ti_de === 4) {
                de.g_dat_gral_ope.g_ope_com.i_tip_tra = values.tipo_trans.i_tip_tra;
                de.g_dat_gral_ope.g_ope_com.d_des_trip_tra = values.tipo_trans.d_des_trip_tra;
            }

            de.g_dat_gral_ope.g_ope_com.i_timp = values.tipo_imp_afectado.i_timp;
            de.g_dat_gral_ope.g_ope_com.d_des_timp = values.tipo_imp_afectado.d_des_timp;
            de.g_dat_gral_ope.g_ope_com.c_mone_ope = values.moneda_operacion.c_mone_ope;
            de.g_dat_gral_ope.g_ope_com.d_des_mone_ope = values.moneda_operacion.d_des_mone_ope;

            if (de.g_dat_gral_ope.g_ope_com.c_mone_ope !== 'PYG') {
                de.g_dat_gral_ope.g_ope_com.d_cond_ti_cam = values.condicion_tipo_cambio.d_cond_ti_cam;
                if (de.g_dat_gral_ope.g_ope_com.d_cond_ti_cam === 1) {
                    de.g_dat_gral_ope.g_ope_com.d_ti_cam = values.d_ti_cam;
                }
            }

            if (!isEmpty(values?.g_obl_afe) && emisor?.imputacion_ventas_rg_90) {
                de.g_dat_gral_ope.g_ope_com.g_obl_afe = [];
                de.g_dat_gral_ope.g_ope_com.g_obl_afe = [...values?.g_obl_afe];
            }
        }

        //2- Generar g_emis
        de.g_dat_gral_ope.g_emis = {};
        de.g_dat_gral_ope.g_emis.d_ruc_em = emisor.ruc;
        de.g_dat_gral_ope.g_emis.d_dv_emi = Number(emisor.dv);
        de.g_dat_gral_ope.g_emis.i_tip_cont = emisor.tipo_contribuyente;

        if (process.env.REACT_APP_SIFEN_ENV === 'test') {
            de.g_dat_gral_ope.g_emis.d_nom_emi = 'DE generado en ambiente de prueba - sin valor comercial ni fiscal';
        } else {
            de.g_dat_gral_ope.g_emis.d_nom_emi = emisor.razon_social;
        }

        de.g_dat_gral_ope.g_emis.d_dir_emi = emisor.direccion;
        de.g_dat_gral_ope.g_emis.d_num_cas = emisor.nro_casa;
        de.g_dat_gral_ope.g_emis.c_dep_emi = emisor.departamento.codigo;
        de.g_dat_gral_ope.g_emis.d_des_dep_emi = emisor.departamento.nombre;
        de.g_dat_gral_ope.g_emis.c_dis_emi = emisor.distrito.codigo;
        de.g_dat_gral_ope.g_emis.d_des_dis_emi = emisor.distrito.nombre;
        de.g_dat_gral_ope.g_emis.c_ciu_emi = emisor.localidad.codigo;
        de.g_dat_gral_ope.g_emis.d_des_ciu_emi = emisor.localidad.nombre;
        de.g_dat_gral_ope.g_emis.d_tel_emi = emisor.telefono;
        de.g_dat_gral_ope.g_emis.d_email_e = emisor.email;
        de.g_dat_gral_ope.g_emis.g_act_eco = {
            c_act_eco: emisor.codigo_actividad,
            d_des_act_eco: emisor.desc_actividad_emisor
        };

        //3- Generar g_dat_rec
        de.g_dat_gral_ope.g_dat_rec = {};
        de.g_dat_gral_ope.g_dat_rec.d_nom_rec = values.d_nom_rec;
        de.g_dat_gral_ope.g_dat_rec.i_nat_rec = values.nat_rec.i_nat_rec;
        de.g_dat_gral_ope.g_dat_rec.i_ti_ope = values.tipo_op_rec.i_ti_ope;
        de.g_dat_gral_ope.g_dat_rec.c_pais_rec = values.pais_rec.codigo;
        de.g_dat_gral_ope.g_dat_rec.d_des_pais_re = values.pais_rec.nombre;
        if (de.g_dat_gral_ope.g_dat_rec.i_nat_rec === 1) {
            de.g_dat_gral_ope.g_dat_rec.i_ti_cont_rec = values.tipo_cont_rec.i_ti_con_rec;
            de.g_dat_gral_ope.g_dat_rec.d_ruc_rec = values.d_ruc_rec;
        }

        if (de.g_dat_gral_ope.g_dat_rec.i_nat_rec === 2) {
            de.g_dat_gral_ope.g_dat_rec.i_tip_id_rec = values.tipo_doc_rec.i_tip_id_rec;
            de.g_dat_gral_ope.g_dat_rec.d_d_tip_id_rec = values.tipo_doc_rec.d_d_tip_id_rec;
            de.g_dat_gral_ope.g_dat_rec.d_num_id_rec = values.d_num_id_rec;

            if (values.tipo_doc_rec.i_tip_id_rec === 9) {
                de.g_dat_gral_ope.g_dat_rec.d_d_tip_id_rec = values.desc_tipo_doc;
            }
        }

        de.g_dat_gral_ope.g_dat_rec.d_dir_rec = values.d_dir_rec || '';
        de.g_dat_gral_ope.g_dat_rec.d_num_cas_rec = values.d_num_cas_rec || '';

        if (!isEmpty(de.g_dat_gral_ope.g_dat_rec.d_dir_rec) && de.g_dat_gral_ope.g_dat_rec.i_ti_ope !== 4) {
            de.g_dat_gral_ope.g_dat_rec.c_dep_rec = values.departamento_rec.codigo;
            de.g_dat_gral_ope.g_dat_rec.d_des_dep_rec = values.departamento_rec.nombre;
            de.g_dat_gral_ope.g_dat_rec.c_dis_rec = values.distrito_rec.codigo;
            de.g_dat_gral_ope.g_dat_rec.d_des_dis_rec = values.distrito_rec.nombre;
            de.g_dat_gral_ope.g_dat_rec.c_ciu_rec = values.localidad_rec.codigo;
            de.g_dat_gral_ope.g_dat_rec.d_des_ciu_rec = values.localidad_rec.nombre;
        }
        de.g_dat_gral_ope.g_dat_rec.d_tel_rec = values.d_tel_rec;
        de.g_dat_gral_ope.g_dat_rec.d_cel_rec = values.d_cel_rec;
        de.g_dat_gral_ope.g_dat_rec.d_email_rec = values.d_email_rec;

        //4- Generar g_d_tip_de
        if (de.g_timb.i_ti_de === 1) {
            de.g_d_tip_de.g_cam_fe = {
                i_ind_pres: values.modalidad_venta.i_ind_pres,
                d_des_ind_pres: values.modalidad_venta.d_des_ind_pres
            };
            if (de.g_dat_gral_ope.g_dat_rec.i_ti_ope === 3) {
                de.g_d_tip_de.g_cam_fe.g_comp_pub = {
                    d_mod_cont: values.d_mod_cont,
                    d_ent_cont: Number(values.d_ent_cont),
                    d_ano_cont: Number(values.d_ano_cont),
                    d_sec_cont: Number(values.d_sec_cont),
                    d_fe_cod_cont: formatToStr(values.d_fe_cod_cont)
                };
            }
        }
        if (de.g_timb.i_ti_de === 1 || de.g_timb.i_ti_de === 4) {
            de.g_d_tip_de.g_cam_cond = {
                i_cond_ope: values.cond_ope.i_cond_ope,
                d_d_cond_ope: values.cond_ope.d_d_cond_ope
            };

            if (de.g_d_tip_de.g_cam_cond.i_cond_ope === 2) {
                de.g_d_tip_de.g_cam_cond.g_pag_cred = {
                    i_cond_cred: values.cond_cred.i_cond_cred,
                    dd_cond_cred: values.cond_cred.dd_cond_cred,
                    d_plazo_cre: values.d_plazo_cre,
                    d_mon_ent: values.d_mon_ent || 0
                };
            }

            if (de.g_d_tip_de.g_cam_cond.i_cond_ope === 1 || de.g_d_tip_de.g_cam_cond.g_pag_cred?.d_mon_ent > 0) {
                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni = {};
                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.i_ti_pago = values.tipo_pago.i_ti_pago;
                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.d_des_ti_pag = values.tipo_pago.d_des_ti_pag;

                let total = 0;

                //si el pago es a crédito y existe un adelanto, es el monto que debemos utilizar
                if (de.g_d_tip_de.g_cam_cond.g_pag_cred?.d_mon_ent > 0) {
                    total = de.g_d_tip_de.g_cam_cond.g_pag_cred?.d_mon_ent;
                } else {
                    //Como solo soportamos un tipo de pago, sumamos el valor total de cada item de la operación
                    values.g_cam_item.forEach((value) => {
                        total += value.g_valor_item.g_valor_resta_item.d_tot_ope_item;
                    });
                }

                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.d_mon_ti_pag = total;
                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.c_mone_ti_pag = values.moneda_tipo_pago.code;
                de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.d_d_mone_ti_pag = values.moneda_tipo_pago.name;

                if (de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.c_mone_ti_pag !== 'PYG') {
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.d_ti_cam_ti_pag = values.d_ti_cam_ti_pag;
                }

                //TODO: completar con los datos faltantes, pero que son opcionales
                if (de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.i_ti_pago === 3 || de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.i_ti_pago === 4) {
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.g_pag_tar_cd = {};
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.g_pag_tar_cd.i_den_tarj = values.iden_tarj.i_den_tarj;
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.g_pag_tar_cd.d_des_den_tarj = values.iden_tarj.d_des_den_tarj;
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.g_pag_tar_cd.i_for_pro_pa = values.forma_proc_pago.i_for_pro_pa;
                }

                if (de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.i_ti_pago === 2) {
                    de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.g_pag_cheq = {
                        d_num_cheq: values.d_num_cheq,
                        d_b_co_emi: values.d_b_co_emi
                    };
                }
            }

            //TODO: completar con los datos faltantes, una vez que la UI soporte el campo de cuotas
            if (de.g_d_tip_de.g_cam_cond.i_cond_ope === 2) {
            }
        }

        //Campos para nota la nota de Crédito/Débito electrónica
        if (de.g_timb.i_ti_de === 5 || de.g_timb.i_ti_de === 6) {
            de.g_d_tip_de.g_cam_ncde = {};
            de.g_d_tip_de.g_cam_ncde.i_mot_emi = values.mot_emision_ncnd.i_mot_emi;
            de.g_d_tip_de.g_cam_ncde.d_des_mot_emi = values.mot_emision_ncnd.d_des_mot_emi;

            de.g_cam_de_asoc = {};
            de.g_cam_de_asoc.i_tip_doc_aso = values.tipo_doc_asoc.i_tip_doc_aso;
            de.g_cam_de_asoc.d_des_tip_doc_aso = values.tipo_doc_asoc.d_des_tip_doc_aso;

            if (de.g_cam_de_asoc.i_tip_doc_aso === 1) {
                de.g_cam_de_asoc.d_cd_cde_ref = values.d_cd_cde_ref;
            }

            if (de.g_cam_de_asoc.i_tip_doc_aso === 2) {
                de.g_cam_de_asoc.dn_tim_di = values.dn_tim_di;
                de.g_cam_de_asoc.d_est_doc_aso = values.d_est_doc_aso;
                de.g_cam_de_asoc.dp_exp_doc_aso = values.dp_exp_doc_aso;
                de.g_cam_de_asoc.d_num_doc_aso = values.d_num_doc_aso;
                de.g_cam_de_asoc.i_tipo_doc_aso = values.tipo_doc_asoc_impreso.i_tipo_doc_aso;
                de.g_cam_de_asoc.dd_tipo_doc_aso = values.tipo_doc_asoc_impreso.dd_tipo_doc_aso;
                de.g_cam_de_asoc.d_fec_emi_di = formatToStr(values.d_fec_emi_di);
            }

            if (de.g_cam_de_asoc.i_tip_doc_aso === 3) {
                de.g_cam_de_asoc.i_tip_cons = values.tipo_constancia.i_tip_cons;
                de.g_cam_de_asoc.d_des_tip_cons = values.tipo_constancia.d_des_tip_cons;
                de.g_cam_de_asoc.d_num_cons = values.d_num_cons;
                de.g_cam_de_asoc.d_num_control = values.d_num_control;
            }
        }

        //Campos para la nota de remisión electrónica
        if (de.g_timb.i_ti_de === 7) {
            de.g_d_tip_de.g_cam_nre = {};
            de.g_d_tip_de.g_cam_nre.i_mot_emi_nr = values.mot_emision_nr.i_mot_emi_nr;
            de.g_d_tip_de.g_cam_nre.d_des_mot_emi_nr = values.mot_emision_nr.d_des_mot_emi_nr;
            de.g_d_tip_de.g_cam_nre.i_resp_emi_nr = values.resp_emision_nr.i_resp_emi_nr;
            de.g_d_tip_de.g_cam_nre.d_des_resp_emi_nr = values.resp_emision_nr.d_des_resp_emi_nr;
            de.g_d_tip_de.g_cam_nre.d_km_r = values.d_km_r || 0;
            de.g_d_tip_de.g_cam_nre.d_fec_em = values.d_fec_em ? formatToStr(values.d_fec_em) : '';

            de.g_d_tip_de.g_transp = {};
            de.g_d_tip_de.g_transp.i_tip_trans = values.tipo_transporte.i_tip_trans;
            de.g_d_tip_de.g_transp.d_des_tip_trans = values.tipo_transporte.d_des_tip_trans;
            de.g_d_tip_de.g_transp.i_mod_trans = values.modalidad_transporte.i_mod_trans;
            de.g_d_tip_de.g_transp.d_des_mod_trans = values.modalidad_transporte.d_des_mod_trans;
            de.g_d_tip_de.g_transp.i_resp_flete = values.responsable_flete.i_resp_flete;
            de.g_d_tip_de.g_transp.c_cond_neg = values.condicion_negociacion?.c_cond_neg ? values.condicion_negociacion?.c_cond_neg : '';
            de.g_d_tip_de.g_transp.d_nu_desp_imp = values.d_nu_desp_imp ? values.d_nu_desp_imp : '';
            de.g_d_tip_de.g_transp.d_ini_tras = formatToStr(values.d_ini_tras);
            de.g_d_tip_de.g_transp.d_fin_tras = formatToStr(values.d_fin_tras);
            de.g_d_tip_de.g_transp.c_pais_dest = values.pais_destino?.c_pais_dest ? values.pais_destino.c_pais_dest : '';
            de.g_d_tip_de.g_transp.d_des_pais_dest = values.pais_destino?.c_pais_dest ? values.pais_destino.d_des_pais_dest : '';

            de.g_d_tip_de.g_transp.g_cam_sal = {};
            de.g_d_tip_de.g_transp.g_cam_sal.d_dir_loc_sal = values.d_dir_loc_sal;
            de.g_d_tip_de.g_transp.g_cam_sal.d_num_cas_sal = Number(values.d_num_cas_sal);
            de.g_d_tip_de.g_transp.g_cam_sal.c_dep_sal = values.dpto_local_salida.codigo;
            de.g_d_tip_de.g_transp.g_cam_sal.d_des_dep_sal = values.dpto_local_salida.nombre;
            de.g_d_tip_de.g_transp.g_cam_sal.c_dis_sal = values.dist_local_salida.codigo;
            de.g_d_tip_de.g_transp.g_cam_sal.d_des_dis_sal = values.dist_local_salida.nombre;
            de.g_d_tip_de.g_transp.g_cam_sal.c_ciu_sal = values.loc_local_salida.codigo;
            de.g_d_tip_de.g_transp.g_cam_sal.d_des_ciu_sal = values.loc_local_salida.nombre;

            de.g_d_tip_de.g_transp.g_cam_ent = {};
            de.g_d_tip_de.g_transp.g_cam_ent.d_dir_loc_ent = values.d_dir_loc_ent;
            de.g_d_tip_de.g_transp.g_cam_ent.d_num_cas_ent = Number(values.d_num_cas_ent);
            de.g_d_tip_de.g_transp.g_cam_ent.c_dep_ent = values.dpto_local_entrega.codigo;
            de.g_d_tip_de.g_transp.g_cam_ent.d_des_dep_ent = values.dpto_local_entrega.nombre;
            de.g_d_tip_de.g_transp.g_cam_ent.c_dis_ent = values.dist_local_entrega.codigo;
            de.g_d_tip_de.g_transp.g_cam_ent.d_des_dis_ent = values.dist_local_entrega.nombre;
            de.g_d_tip_de.g_transp.g_cam_ent.c_ciu_ent = values.loc_local_entrega.codigo;
            de.g_d_tip_de.g_transp.g_cam_ent.d_des_ciu_ent = values.loc_local_entrega.nombre;

            de.g_d_tip_de.g_transp.g_veh_tras = {};
            de.g_d_tip_de.g_transp.g_veh_tras.d_ti_veh_tras = values.d_ti_veh_tras;
            de.g_d_tip_de.g_transp.g_veh_tras.d_mar_veh = values.d_mar_veh;
            de.g_d_tip_de.g_transp.g_veh_tras.d_tip_iden_veh = values.tipo_iden_veh.d_tip_iden_veh;
            de.g_d_tip_de.g_transp.g_veh_tras.d_adic_veh = values.d_adic_veh || '';

            if (de.g_d_tip_de.g_transp.g_veh_tras.d_tip_iden_veh === 1) {
                de.g_d_tip_de.g_transp.g_veh_tras.d_nro_id_veh = values.d_nro_id_veh;
            } else {
                de.g_d_tip_de.g_transp.g_veh_tras.d_nro_mat_veh = values.d_nro_mat_veh;
            }

            if (de.g_d_tip_de.g_transp.i_mod_trans === 3) {
                de.g_d_tip_de.g_transp.g_veh_tras.d_nro_vuelo = values.d_nro_vuelo;
            }

            de.g_d_tip_de.g_transp.g_cam_trans = {};
            de.g_d_tip_de.g_transp.g_cam_trans.i_nat_trans = values.naturaleza_transportista.i_nat_trans;
            de.g_d_tip_de.g_transp.g_cam_trans.d_nom_trans = values.d_nom_trans;

            if (de.g_d_tip_de.g_transp.g_cam_trans.i_nat_trans === 1) {
                de.g_d_tip_de.g_transp.g_cam_trans.d_ruc_trans = values.d_ruc_trans;
            } else {
                de.g_d_tip_de.g_transp.g_cam_trans.i_tip_id_trans = values.tipo_documento_transportista.i_tip_id_trans;
                de.g_d_tip_de.g_transp.g_cam_trans.dd_tip_id_trans = values.tipo_documento_transportista.dd_tip_id_trans;
                de.g_d_tip_de.g_transp.g_cam_trans.d_num_id_trans = values.d_num_id_trans;
            }

            de.g_d_tip_de.g_transp.g_cam_trans.d_num_id_chof = values.d_num_id_chof;
            de.g_d_tip_de.g_transp.g_cam_trans.d_nom_chof = values.d_nom_chof;
            de.g_d_tip_de.g_transp.g_cam_trans.d_dom_fisc = values.d_dom_fisc;
            de.g_d_tip_de.g_transp.g_cam_trans.d_dir_chof = values.d_dir_chof;
        }

        de.g_d_tip_de.g_cam_item = [];
        values.g_cam_item.forEach((value) => {
            let item = {
                d_cod_int: value.d_cod_int,
                d_des_pro_ser: value.d_des_pro_ser,
                c_uni_med: value.unidad_medida?.c_uni_med || 77, //Valor por defecto si no es proveído
                d_des_uni_med: value.unidad_medida?.d_des_uni_med || 'UNI', //Valor por defecto si no es proveído
                d_cant_pro_ser: value.d_cant_pro_ser
            };

            if (de.g_timb.i_ti_de !== 7) {
                item.g_valor_item = {
                    d_p_uni_pro_ser: value.g_valor_item.d_p_uni_pro_ser,
                    d_tot_bru_ope_item: value.g_valor_item.d_tot_bru_ope_item,
                    g_valor_resta_item: {
                        d_desc_item: value.g_valor_item.g_valor_resta_item.d_desc_item || 0,
                        d_porc_des_it: value.g_valor_item.g_valor_resta_item.d_porc_des_it || 0,
                        d_desc_glo_item: value.g_valor_item.g_valor_resta_item.d_desc_glo_item || 0,
                        d_ant_pre_uni_lt: value.g_valor_item.g_valor_resta_item.d_ant_pre_uni_lt || 0,
                        d_ant_glo_pre_unit_lt: value.g_valor_item.g_valor_resta_item.d_ant_glo_pre_unit_lt || 0,
                        d_tot_ope_item: value.g_valor_item.g_valor_resta_item.d_tot_ope_item,
                        d_tot_ope_gs: value.g_valor_item.g_valor_resta_item.d_tot_ope_gs || 0
                    }
                };
            }

            if (de.g_d_tip_de?.g_ope_com?.i_timp !== 2 && de.g_timb.i_ti_de !== 7 && de.g_timb.i_ti_de !== 4) {
                item.g_cam_iva = {
                    i_afec_iva: value.g_cam_iva.afectacion_tributaria.i_afec_iva,
                    d_des_afec_iva: value.g_cam_iva.afectacion_tributaria.d_des_afec_iva,
                    d_prop_iva: value.g_cam_iva.d_prop_iva,
                    d_tasa_iva: value.g_cam_iva.d_tasa_iva
                };
            }
            if (de.g_dat_gral_ope.g_dat_rec.i_ti_ope === 3 || de.g_timb.i_ti_de === 1) {
                item.d_dncp_g = value.d_dncp_g;
                item.d_dncp_e = value.d_dncp_e;
            }
            de.g_d_tip_de.g_cam_item = [...de.g_d_tip_de.g_cam_item, item];
        });

        return de;
    };

    const fillFormik = (de) => {
        const g_emis = {
            ruc: {
                code: de.g_dat_gral_ope?.g_emis?.d_ruc_em,
                name: '' //TODO: check if we really need to do this
            }
        };
        issuerOnChange(g_emis.ruc);

        const tipo_de = {
            d_des_ti_de: de.g_timb?.d_des_ti_de,
            i_ti_de: de.g_timb?.i_ti_de
        };
        formik.setFieldValue('tipo_de', tipo_de, true);

        const timbrado = {
            nro_timbrado: de.g_timb?.d_num_tim,
            establecimiento: Number(de.g_timb?.d_est), //de.g_timb?.d_est.replace(/^0+/, ''), //removes leading zeroes
            punto_expedicion: Number(de.g_timb?.d_pun_exp), //de.g_timb?.d_pun_exp.replace(/^0+/, ''), //removed leading zeroes
            name: `${de.g_timb?.d_num_tim}-${de.g_timb?.d_est.toString().padStart(3, '0')}-${de.g_timb?.d_pun_exp.toString().padStart(3, '0')}`
        };
        formik.setFieldValue('timbrado', timbrado, true);

        if (de.g_timb?.i_ti_de !== 7) {
            if (de.g_timb.i_ti_de === 1 || de.g_timb.i_ti_de === 4) {
                const tipo_trans = {
                    i_tip_tra: de.g_dat_gral_ope?.g_ope_com?.i_tip_tra,
                    d_des_trip_tra: de.g_dat_gral_ope?.g_ope_com?.d_des_trip_tra
                };
                formik.setFieldValue('tipo_trans', tipo_trans, true);
            }

            const tipo_imp_afectado = {
                i_timp: de.g_dat_gral_ope?.g_ope_com?.i_timp,
                d_des_timp: de.g_dat_gral_ope?.g_ope_com?.d_des_timp
            };
            formik.setFieldValue('tipo_imp_afectado', tipo_imp_afectado, true);

            const moneda_operacion = {
                c_mone_ope: de.g_dat_gral_ope?.g_ope_com?.c_mone_ope,
                d_des_mone_ope: de.g_dat_gral_ope?.g_ope_com?.d_des_mone_ope
            };
            formik.setFieldValue('moneda_operacion', moneda_operacion);

            if (de.g_dat_gral_ope.g_ope_com.c_mone_ope !== 'PYG') {
                const condicion_tipo_cambio = {
                    d_cond_ti_cam: de.g_dat_gral_ope?.g_ope_com?.d_cond_ti_cam,
                    d_des_cond_ti_cam: 'Global' //TODO: We only support Global exchange for now
                };
                formik.setFieldValue('condicion_tipo_cambio', condicion_tipo_cambio);
                if (de.g_dat_gral_ope.g_ope_com.d_cond_ti_cam === 1) {
                    formik.setFieldValue('d_ti_cam', de.g_dat_gral_ope?.g_ope_com?.d_ti_cam || 0);
                }
            }

            if (!isEmpty(de.g_dat_gral_ope?.g_ope_com?.g_obl_afe) && emisor?.imputacion_ventas_rg_90) {
                formik.setFieldValue('g_obl_afe', [...de.g_dat_gral_ope.g_ope_com.g_obl_afe]);
            }
        }

        const d_nom_rec = de.g_dat_gral_ope?.g_dat_rec.d_nom_rec;
        formik.setFieldValue('d_nom_rec', d_nom_rec);

        const nat_rec = NATURALEZA_RECEPTOR.filter((nat_rec) => nat_rec.i_nat_rec === de.g_dat_gral_ope?.g_dat_rec?.i_nat_rec);
        formik.setFieldValue('nat_rec', nat_rec[0]);

        const tipo_op_rec = TIPO_OPERACION_RECEPTOR.filter((op_rec) => op_rec.i_ti_ope === de.g_dat_gral_ope?.g_dat_rec?.i_ti_ope);
        formik.setFieldValue('tipo_op_rec', tipo_op_rec[0]);

        const pais_rec = {
            codigo: de.g_dat_gral_ope?.g_dat_rec?.c_pais_rec,
            nombre: de.g_dat_gral_ope.g_dat_rec.d_des_pais_re
        };
        formik.setFieldValue('pais_rec', pais_rec);

        if (de.g_dat_gral_ope.g_dat_rec.i_nat_rec === 1) {
            const tipo_cont_rec = TIPO_CONTRIBUYENTE_RECEPTOR.filter((item) => item.i_ti_con_rec === de.g_dat_gral_ope?.g_dat_rec?.i_ti_cont_rec);
            formik.setFieldValue('tipo_cont_rec', tipo_cont_rec[0]);
            formik.setFieldValue('d_ruc_rec', de.g_dat_gral_ope?.g_dat_rec?.d_ruc_rec);
        }

        if (de.g_dat_gral_ope?.g_dat_rec?.i_nat_rec === 2) {
            const tipo_doc_rec = {
                i_tip_id_rec: de.g_dat_gral_ope?.g_dat_rec?.i_tip_id_rec,
                d_d_tip_id_rec: de.g_dat_gral_ope?.g_dat_rec?.d_d_tip_id_rec
            };

            if (de.g_dat_gral_ope?.g_dat_rec?.i_tip_id_rec === 9) {
                tipo_doc_rec.d_d_tip_id_rec = 'Otro';
                formik.setFieldValue('desc_tipo_doc', de.g_dat_gral_ope?.g_dat_rec?.d_d_tip_id_rec);
                formik.setFieldValue('tipo_doc_rec', tipo_doc_rec);
            } else {
                //Leave as is
                formik.setFieldValue('tipo_doc_rec', tipo_doc_rec);
            }
            formik.setFieldValue('d_num_id_rec', de.g_dat_gral_ope?.g_dat_rec?.d_num_id_rec);
        }

        formik.setFieldValue('d_dir_rec', de.g_dat_gral_ope?.g_dat_rec?.d_dir_rec || '');
        formik.setFieldValue('d_num_cas_rec', de.g_dat_gral_ope?.g_dat_rec?.d_num_cas_rec || '');

        if (!isEmpty(de.g_dat_gral_ope?.g_dat_rec?.d_dir_rec) && de.g_dat_gral_ope?.g_dat_rec?.i_ti_ope !== 4) {
            const departamento_rec = {
                codigo: de.g_dat_gral_ope?.g_dat_rec.c_dep_rec,
                nombre: de.g_dat_gral_ope?.g_dat_rec.d_des_dep_rec
            };
            formik.setFieldValue('departamento_rec', departamento_rec);

            const distrito_rec = {
                codigo: de.g_dat_gral_ope?.g_dat_rec?.c_dis_rec,
                nombre: de.g_dat_gral_ope?.g_dat_rec?.d_des_dis_rec
            };
            formik.setFieldValue('distrito_rec', distrito_rec);

            const localidad_rec = {
                codigo: de.g_dat_gral_ope?.g_dat_rec?.c_ciu_rec,
                nombre: de.g_dat_gral_ope?.g_dat_rec?.d_des_ciu_rec
            };
            formik.setFieldValue('localidad_rec', localidad_rec);
        }

        formik.setFieldValue('d_tel_rec', de.g_dat_gral_ope?.g_dat_rec?.d_tel_rec || '');
        formik.setFieldValue('d_cel_rec', de.g_dat_gral_ope?.g_dat_rec?.d_cel_rec || '');
        formik.setFieldValue('d_email_rec', de.g_dat_gral_ope?.g_dat_rec?.d_email_rec || '');

        //4- Generar g_d_tip_de
        if (de.g_timb.i_ti_de === 1) {
            const modalidad_venta = {
                i_ind_pres: de.g_d_tip_de?.g_cam_fe?.i_ind_pres,
                d_des_ind_pres: de.g_d_tip_de?.g_cam_fe?.d_des_ind_pres
            };
            formik.setFieldValue('modalidad_venta', modalidad_venta);

            if (de.g_dat_gral_ope?.g_dat_rec?.i_ti_ope === 3) {
                formik.setFieldValue('d_mod_cont', de.g_d_tip_de?.g_cam_fe?.g_comp_pub?.d_mod_cont || 0);
                formik.setFieldValue('d_ent_cont', de.g_d_tip_de?.g_cam_fe?.g_comp_pub?.d_ent_cont || '');
                formik.setFieldValue('d_ano_cont', de.g_d_tip_de?.g_cam_fe?.g_comp_pub?.d_ano_cont || 0);
                formik.setFieldValue('d_sec_cont', de.g_d_tip_de?.g_cam_fe?.g_comp_pub?.d_sec_cont || 0);
                formik.setFieldValue('d_fe_cod_cont', new Date(de.g_d_tip_de?.g_cam_fe?.g_comp_pub?.d_fe_cod_cont) || undefined);
            }
        }

        if (de.g_timb?.i_ti_de === 1 || de.g_timb?.i_ti_de === 4) {
            const cond_ope = {
                i_cond_ope: de.g_d_tip_de?.g_cam_cond?.i_cond_ope,
                d_d_cond_ope: de.g_d_tip_de?.g_cam_cond?.d_d_cond_ope
            };
            formik.setFieldValue('cond_ope', cond_ope);

            if (de.g_d_tip_de.g_cam_cond.i_cond_ope === 2) {
                const cond_cred = {
                    i_cond_cred: de.g_d_tip_de?.g_cam_cond?.g_pag_cred?.i_cond_cred,
                    dd_cond_cred: de.g_d_tip_de?.g_cam_cond?.g_pag_cred?.dd_cond_cred
                };
                formik.setFieldValue('cond_cred', cond_cred);
                formik.setFieldValue('d_plazo_cre', de.g_d_tip_de?.g_cam_cond?.g_pag_cred?.d_plazo_cre);
                formik.setFieldValue('d_mon_ent', de.g_d_tip_de?.g_cam_cond?.g_pag_cred?.d_mon_ent || 0);
            }

            if (de.g_d_tip_de?.g_cam_cond?.i_cond_ope === 1 || de.g_d_tip_de?.g_cam_cond?.g_pag_cred?.d_mon_ent > 0) {
                const tipo_pago = {
                    i_ti_pago: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.i_ti_pago,
                    d_des_ti_pag: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.d_des_ti_pag
                };
                formik.setFieldValue('tipo_pago', tipo_pago);
                formik.setFieldValue('d_mon_ti_pag', de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.d_mon_ti_pag);

                const moneda_tipo_pago = {
                    code: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.c_mone_ti_pag,
                    name: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.d_d_mone_ti_pag
                };
                formik.setFieldValue('moneda_tipo_pago', moneda_tipo_pago);

                if (de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.c_mone_ti_pag !== 'PYG') {
                    formik.setFieldValue('d_ti_cam_ti_pag', de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.d_ti_cam_ti_pag);
                }
                //TODO: completar con los datos faltantes, pero que son opcionales
                if (de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.i_ti_pago === 3 || de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.i_ti_pago === 4) {
                    const iden_tarj = {
                        i_den_tarj: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.g_pag_tar_cd?.i_den_tarj,
                        d_des_den_tarj: de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.g_pag_tar_cd?.d_des_den_tarj
                    };
                    formik.setFieldValue('iden_tarj', iden_tarj);

                    const forma_proc_pago = LISTA_FORMA_PROC_PAGO.find((value) => value.i_for_pro_pa === de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.g_pag_tar_cd?.i_for_pro_pa);
                    formik.setFieldValue('forma_proc_pago', forma_proc_pago);
                }

                if (de.g_d_tip_de.g_cam_cond.g_pa_con_el_ni.i_ti_pago === 2) {
                    formik.setFieldValue('d_num_cheq', de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.g_pag_cheq?.d_num_cheq);
                    formik.setFieldValue('d_b_co_emi', de.g_d_tip_de?.g_cam_cond?.g_pa_con_el_ni?.g_pag_cheq?.d_b_co_emi);
                }
            }
            //TODO: completar con los datos faltantes, una vez que la UI soporte el campo de cuotas
            if (de.g_d_tip_de?.g_cam_cond?.i_cond_ope === 2) {
            }
        }

        //Campos para nota la nota de Crédito/Débito electrónica
        if (de.g_timb?.i_ti_de === 5 || de.g_timb?.i_ti_de === 6) {
            const mot_emision_ncn = {
                i_mot_emi: de.g_d_tip_de?.g_cam_ncde?.i_mot_emi,
                d_des_mot_emi: de.g_d_tip_de?.g_cam_ncde?.d_des_mot_emi
            };
            formik.setFieldValue('mot_emision_ncn', mot_emision_ncn);

            const tipo_doc_aso = {
                i_tip_doc_aso: de.g_cam_de_asoc?.i_tip_doc_aso,
                d_des_tip_doc_aso: de.g_cam_de_asoc?.d_des_tip_doc_aso
            };
            formik.setFieldValue('tipo_doc_aso', tipo_doc_aso);

            if (de.g_cam_de_asoc?.i_tip_doc_aso === 1) {
                formik.setFieldValue('d_cd_cde_ref', de.g_cam_de_asoc.d_cd_cde_ref);
            }

            if (de.g_cam_de_asoc?.i_tip_doc_aso === 2) {
                formik.setFieldValue('dn_tim_di', de.g_cam_de_asoc?.dn_tim_di);
                formik.setFieldValue('d_est_doc_aso', de.g_cam_de_asoc?.d_est_doc_aso);
                formik.setFieldValue('dp_exp_doc_aso', de.g_cam_de_asoc?.dp_exp_doc_aso);
                formik.setFieldValue('d_num_doc_aso', de.g_cam_de_asoc?.d_num_doc_aso);

                const tipo_doc_asoc_impreso = {
                    i_tipo_doc_aso: de.g_cam_de_asoc?.i_tipo_doc_aso,
                    dd_tipo_doc_aso: de.g_cam_de_asoc?.dd_tipo_doc_aso
                };
                formik.setFieldValue('tipo_doc_asoc_impreso', tipo_doc_asoc_impreso);
                formik.setFieldValue('d_fec_emi_di', new Date(de.g_cam_de_asoc?.d_fec_emi_di || undefined));
            }

            if (de.g_cam_de_asoc.i_tip_doc_aso === 3) {
                const tipo_constancia = {
                    i_tip_cons: de.g_cam_de_asoc?.i_tip_cons,
                    d_des_tip_cons: de.g_cam_de_asoc?.d_des_tip_cons
                };
                formik.setFieldValue('tipo_constancia', tipo_constancia);
                formik.setFieldValue('d_num_cons', de.g_cam_de_asoc?.d_num_cons);
                formik.setFieldValue('d_num_control', de.g_cam_de_asoc?.d_num_control);
            }
        }

        //Campos para la nota de remisión electrónica
        if (de.g_timb?.i_ti_de === 7) {
            formik.setFieldValue('mot_emision_nr', {
                i_mot_emi_nr: de.g_d_tip_de?.g_cam_nre?.i_mot_emi_nr,
                d_des_mot_emi_nr: de.g_d_tip_de?.g_cam_nre?.d_des_mot_emi_nr
            });
            formik.setFieldValue('resp_emision_nr', {
                i_resp_emi_nr: de.g_d_tip_de?.g_cam_nre?.i_resp_emi_nr,
                d_des_resp_emi_nr: de.g_d_tip_de?.g_cam_nre?.d_des_resp_emi_nr
            });
            formik.setFieldValue('d_km_r', de.g_d_tip_de?.g_cam_nre?.d_km_r);
            formik.setFieldValue('d_fec_em', new Date(de.g_d_tip_de?.g_cam_nre?.d_fec_em));
            formik.setFieldValue('tipo_transporte', {
                i_tip_trans: de.g_d_tip_de?.g_transp?.i_tip_trans,
                d_des_tip_trans: de.g_d_tip_de?.g_transp?.d_des_tip_trans
            });
            formik.setFieldValue('modalidad_transporte', {
                i_mod_trans: de.g_d_tip_de?.g_transp?.i_mod_trans,
                d_des_mod_trans: de.g_d_tip_de?.g_transp?.d_des_mod_trans
            });
            formik.setFieldValue(
                'responsable_flete',
                RESPONSABLE_COSTO_FLETE.find((val) => val.i_resp_flete === de.g_d_tip_de?.g_transp?.i_resp_flete)
            );
            formik.setFieldValue(
                'condicion_negociacion',
                CONDICION_NEGOCIACION.find((val) => val.c_cond_neg === de.g_d_tip_de?.g_transp?.c_cond_neg)
            );
            formik.setFieldValue('d_nu_desp_imp', de.g_d_tip_de?.g_transp?.d_nu_desp_imp);
            formik.setFieldValue('d_ini_tras', new Date(de.g_d_tip_de.g_transp.d_ini_tras));
            formik.setFieldValue('d_fin_tras', new Date(de.g_d_tip_de.g_transp.d_fin_tras));
            formik.setFieldValue('pais_destino', {
                c_pais_dest: de.g_d_tip_de?.g_transp?.c_pais_dest,
                d_des_pais_dest: de.g_d_tip_de?.g_transp?.d_des_pais_dest
            });

            formik.setFieldValue('d_dir_loc_sal', de.g_d_tip_de?.g_transp?.g_cam_sal?.d_dir_loc_sal);
            formik.setFieldValue('d_num_cas_sal', de.g_d_tip_de?.g_transp?.g_cam_sal?.d_num_cas_sal);
            formik.setFieldValue('dpto_local_salida', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_sal?.c_dep_sal,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_sal?.d_des_dep_sal
            });
            formik.setFieldValue('dist_local_salida', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_sal?.c_dis_sal,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_sal?.d_des_dis_sal
            });
            formik.setFieldValue('loc_local_salida', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_sal?.c_ciu_sal,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_sal?.d_des_ciu_sal
            });

            formik.setFieldValue('d_dir_loc_ent', de.g_d_tip_de?.g_transp?.g_cam_ent?.d_dir_loc_ent);
            formik.setFieldValue('d_num_cas_ent', de.g_d_tip_de?.g_transp?.g_cam_ent?.d_num_cas_ent);
            formik.setFieldValue('dpto_local_entrega', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_ent?.c_dep_ent,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_ent?.d_des_dep_ent
            });
            formik.setFieldValue('dist_local_entrega', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_ent.c_dis_ent,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_ent?.d_des_dis_ent
            });
            formik.setFieldValue('loc_local_entrega', {
                codigo: de.g_d_tip_de?.g_transp?.g_cam_ent?.c_ciu_ent,
                nombre: de.g_d_tip_de?.g_transp?.g_cam_ent?.d_des_ciu_ent
            });

            formik.setFieldValue('d_ti_veh_tras', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_ti_veh_tras);
            formik.setFieldValue('d_mar_veh', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_mar_veh);
            formik.setFieldValue(
                'tipo_iden_veh',
                TIPO_IDENTIFICACION_VEHICULO.find((val) => val.d_tip_iden_veh === de.g_d_tip_de?.g_transp?.g_veh_tras?.d_tip_iden_veh)
            );
            formik.setFieldValue('d_adic_veh', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_adic_veh || '');

            if (de.g_d_tip_de?.g_transp?.g_veh_tras?.d_tip_iden_veh === 1) {
                formik.setFieldValue('d_nro_id_veh', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_nro_id_veh);
            } else {
                formik.setFieldValue('d_nro_mat_veh', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_nro_mat_veh);
            }

            if (de.g_d_tip_de?.g_transp?.i_mod_trans === 3) {
                formik.setFieldValue('d_nro_vuelo', de.g_d_tip_de?.g_transp?.g_veh_tras?.d_nro_vuelo);
            }

            formik.setFieldValue(
                'naturaleza_transportista',
                NATURALEZA_TRANSPORTISTA.find((val) => val.i_nat_trans === de.g_d_tip_de?.g_transp?.g_cam_trans?.i_nat_trans)
            );
            formik.setFieldValue('d_nom_trans', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_nom_trans);

            if (de.g_d_tip_de?.g_transp?.g_cam_trans?.i_nat_trans === 1) {
                formik.setFieldValue('d_ruc_trans', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_ruc_trans);
            } else {
                formik.setFieldValue('tipo_documento_transportista', {
                    i_tip_id_trans: de.g_d_tip_de?.g_transp?.g_cam_trans?.i_tip_id_trans,
                    dd_tip_id_trans: de.g_d_tip_de?.g_transp?.g_cam_trans?.dd_tip_id_trans
                });
                formik.setFieldValue('d_num_id_trans', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_num_id_trans);
            }
            formik.setFieldValue('d_num_id_chof', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_num_id_chof);
            formik.setFieldValue('d_nom_chof', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_nom_chof);
            formik.setFieldValue('d_dom_fisc', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_dom_fisc);
            formik.setFieldValue('d_dir_chof', de.g_d_tip_de?.g_transp?.g_cam_trans?.d_dir_chof);
        }

        const newItems = [];

        de.g_d_tip_de?.g_cam_item?.forEach((val) => {
            let item = {
                d_cod_int: val.d_cod_int,
                d_des_pro_ser: val.d_des_pro_ser,
                unidad_medida: {
                    c_uni_med: val.c_uni_med,
                    d_des_uni_med: val.d_des_uni_med
                },
                d_cant_pro_ser: val.d_cant_pro_ser
            };

            //TODO: cuando agreguemos el tipo de cambio por item y la moneda
            // sea diferente a PYG, debemos actualizar los campos
            if (de.g_timb.i_ti_de !== 7) {
                item.g_valor_item = {
                    d_p_uni_pro_ser: val.g_valor_item?.d_p_uni_pro_ser,
                    d_tot_bru_ope_item: val.g_valor_item?.d_tot_bru_ope_item,
                    g_valor_resta_item: {
                        d_desc_item: val.g_valor_item?.g_valor_resta_item?.d_desc_item || 0,
                        d_porc_des_it: val.g_valor_item?.g_valor_resta_item?.d_porc_des_it || 0,
                        d_desc_glo_item: val.g_valor_item?.g_valor_resta_item?.d_desc_glo_item || 0,
                        d_ant_pre_uni_lt: val.g_valor_item?.g_valor_resta_item?.d_ant_pre_uni_lt || 0,
                        d_ant_glo_pre_unit_lt: val.g_valor_item?.g_valor_resta_item?.d_ant_glo_pre_unit_lt || 0,
                        d_tot_ope_item: val.g_valor_item?.g_valor_resta_item?.d_tot_ope_item,
                        d_tot_ope_gs: val.g_valor_item?.g_valor_resta_item?.d_tot_ope_gs || 0
                    }
                };
            }

            if (de.g_d_tip_de?.g_ope_com?.i_timp !== 2 && de.g_timb?.i_ti_de !== 7 && de.g_timb?.i_ti_de !== 4) {
                item.g_cam_iva = {
                    afectacion_tributaria: {
                        i_afec_iva: val.g_cam_iva?.i_afec_iva,
                        d_des_afec_iva: val.g_cam_iva?.d_des_afec_iva
                    },
                    d_prop_iva: val.g_cam_iva.d_prop_iva,
                    d_tasa_iva: val.g_cam_iva.d_tasa_iva
                };
            }
            if (de.g_dat_gral_ope?.g_dat_rec?.i_ti_ope === 3 || de.g_timb?.i_ti_de === 1) {
                item.d_dncp_g = val.d_dncp_g;
                item.d_dncp_e = val.d_dncp_e;
            }

            newItems.push(item);
        });
        formik.setFieldValue('g_cam_item', newItems);
    };

    const formik = useFormik({
        initialValues: {
            d_fe_emi_de: new Date()
        },
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: onSubmit
    });

    const isFormFieldTouched = (name) => {
        return get(formik.touched, name);
    };

    const formFieldError = (name) => {
        return get(formik.errors, name);
    };

    const isFormFieldInvalid = (name) => {
        return !!(isFormFieldTouched(name) && formFieldError(name));
    };

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formFieldError(name)}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onRUCError = () => {
        toast.current.show({ severity: 'error', summary: 'Error al buscar el RUC', detail: 'RUC no existe', life: 3000 });
    };

    const onError = (error) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.error, sticky: true });
    };

    const onTimbradoChange = (e) => {
        formik.setFieldValue('timbrado', e.value);
    };

    const onBlurTimbrado = () => {
        formik.setFieldTouched('timbrado', true);
    };

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Crear Documento electrónico</h5>
                        <Toast ref={toast} />
                        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-4">
                                    <FloatLabel>
                                        {isLoading ? (
                                            <Skeleton id="emisor" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <React.Fragment>
                                                <SeleccionarEmisor
                                                    id="emisor"
                                                    value={formik.values.g_emis?.ruc}
                                                    onChange={issuerOnChange}
                                                    filterByTimbrado={true}
                                                    onBlur={issuerOnBlur}
                                                    disabled={isEditing}
                                                    skeletonHeigh={'3rem'}
                                                    placeHolder="Seleccione un emisor"
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('g_emis.ruc') })}
                                                />
                                                <label htmlFor="emisor">Emisor</label>
                                                {getFormErrorMessage('g_emis')}
                                            </React.Fragment>
                                        )}
                                    </FloatLabel>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <FloatLabel>
                                        {isLoading ? (
                                            <Skeleton id="tipoDE" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <React.Fragment>
                                                <AutoFocusDropdown
                                                    id="tipoDE"
                                                    value={formik.values.tipo_de}
                                                    onChange={(e) => formik.setFieldValue('tipo_de', e.value)}
                                                    onBlur={() => formik.setFieldTouched('tipo_de', true)}
                                                    options={LISTA_DE}
                                                    checkmark={true}
                                                    disabled={isEditing}
                                                    optionLabel="d_des_ti_de"
                                                    placeholder="Seleccione el tipo de documento electrónico"
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('tipo_de') })}
                                                />
                                                <label htmlFor="tipoDE">Tipo de Documento Electrónico</label>
                                                {getFormErrorMessage('tipo_de')}
                                            </React.Fragment>
                                        )}
                                    </FloatLabel>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <FloatLabel>
                                        {isLoading ? (
                                            <Skeleton id="timbrado" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <React.Fragment>
                                                {isEditing ? (
                                                    <InputText disabled value={formik.values.timbrado?.name} />
                                                ) : (
                                                    <SeleccionarTimbrado
                                                        id="timbrado"
                                                        value={formik.values.timbrado}
                                                        ruc={formik.values.g_emis?.ruc?.code}
                                                        tipoDE={formik.values.tipo_de?.i_ti_de}
                                                        onChange={onTimbradoChange}
                                                        onBlur={onBlurTimbrado}
                                                        className={classNames({ 'p-invalid': isFormFieldInvalid('timbrado.nro_timbrado') })}
                                                    />
                                                )}
                                                <label htmlFor="timbrado">Seleccionar timbrado</label>
                                                {getFormErrorMessage('timbrado.nro_timbrado')}
                                            </React.Fragment>
                                        )}
                                    </FloatLabel>
                                </div>
                            </div>
                            <GOpeCom formik={formik} emisor={emisor} getFormErrorMessage={getFormErrorMessage} isFormFieldInvalid={isFormFieldInvalid} isEditing={isEditing} />
                            <GDatRec formik={formik} onError={onRUCError} onRUCNotFound={onRUCError} getFormErrorMessage={getFormErrorMessage} isFormFieldInvalid={isFormFieldInvalid} />
                            {(formik.values.tipo_de?.i_ti_de === 4 || formik.values.tipo_de?.i_ti_de === 5 || formik.values.tipo_de?.i_ti_de === 6) && (
                                <GCamDEAsoc formik={formik} onError={onRUCError} onRUCNotFound={onRUCError} getFormErrorMessage={getFormErrorMessage} isFormFieldInvalid={isFormFieldInvalid} />
                            )}
                            <GDTipDE formik={formik} getFormErrorMessage={getFormErrorMessage} isFormFieldInvalid={isFormFieldInvalid} onError={onError} isEditing={isEditing} fieldsLoading={isLoading} />
                            <div className="flex flex-wrap justify-content-end gap-2">
                                <Button type="button" className=" p-button-danger field col-12 md:col-1" onClick={() => navigate('/administracion/documentos')} label="Cancelar" />
                                <Button type="submit" className="field col-12 md:col-1" loading={isLoading} disabled={!formik.isValid || isEmpty(formik.values.g_cam_item)} label="Guardar" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CrearDocumentoElectronico;
