export const LISTA_DE = [
    { d_des_ti_de: 'Factura electrónica', i_ti_de: 1 },
    // { d_des_ti_de: 'Factura electrónica de exportación', i_ti_de: 2 },
    // { d_des_ti_de: 'Factura electrónica de importación', i_ti_de: 3 },
    // { d_des_ti_de: 'Autofactura electrónica', i_ti_de: 4 },
    { d_des_ti_de: 'Nota de crédito electrónica', i_ti_de: 5 },
    { d_des_ti_de: 'Nota de débito electrónica', i_ti_de: 6 },
    { d_des_ti_de: 'Nota de remisión electrónica', i_ti_de: 7 }
    // { d_des_ti_de: 'Comprobante de retención electrónico', i_ti_de: 8 }
];
