import { useHttpClient } from '../hooks/useHttpClient';
import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { isEmpty } from 'lodash';
import AutoFocusDropdown from './AutoFocusDropdown';

const SeleccionarTimbrado = (props) => {
    const httpClient = useHttpClient();
    const [timbrados, setTimbrados] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isEmpty(props.ruc) && props.tipoDE > 0) {
            setIsLoading(true);
            httpClient
                .get(`admin/emisor/timbrados/${props.ruc}`)
                .then((response) => {
                    let tmp = [];
                    response.data.forEach((item) => {
                        if (item.tipo_de === props.tipoDE) {
                            item.name = `${item.nro_timbrado}-${item.establecimiento.toString().padStart(3, '0')}-${item.punto_expedicion.toString().padStart(3, '0')}`;
                            tmp = [...tmp, item];
                        }
                    });
                    setTimbrados(tmp);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ruc, props.tipoDE]);

    const timbradoOptionTemplate = (option) => {
        return (
            <div className="flex align-items-start" style={{ display: 'flex', flexDirection: 'column' }}>
                <p>
                    <b>Nro. timbrado:</b> {option.nro_timbrado}
                </p>
                <p>
                    <b>Establecimiento:</b> {option.establecimiento}
                </p>
                <p>
                    <b>Punto Expedición:</b> {option.punto_expedicion}
                </p>
            </div>
        );
    };

    const selectedTimbradoTemplate = (option, props) => {
        if (option) {
            return <span>{option.name}</span>;
        }
        return <span>{props.placeholder}</span>;
    };

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        return (
            <AutoFocusDropdown
                id={props.id}
                filter
                value={props.value}
                itemTemplate={timbradoOptionTemplate}
                valueTemplate={selectedTimbradoTemplate}
                optionLabel="name"
                checkmark={true}
                onChange={(e) => props.onChange(e)}
                onBlur={() => props.onBlur()}
                options={timbrados}
                placeholder="Seleccione el timbrado"
                className={props.className}
                virtualScrollerOptions={{ itemSize: 110 }}
            />
        );
    };

    return getSelect();
};

export default SeleccionarTimbrado;
